const styles: any = {
    outerCardContainer: {
        width: "100%",
    },
    headerContainer: {
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "1.5rem"
    },
    outerCard: {
        minWidth: "80vw",
        maxWidth: "80vw",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#ffffff",
        padding: "26px 4rem",
        borderRadius: "15px",
        boxShadow: "0 0"
    },
    completionStatus: { color: "#929292", lineHeight: 0.8 },
    unpackingCompletionText: {
        fontSize: "16px",
        color: "#929292",
        textAlign: "right",
        paddingRight: "2vw",
        paddingBottom: "1vh",
    },
    speechIcon: {
        color: "green",
        position: "relative",
        marginTop: "-190px",
        marginRight: "1px"
    },
    radioButtonOptions: {
        border: "1px solid #f5f8fb",
        background: "#f5f8fb",
        color: "black",
        borderRadius: "32px",
        margin: "10px",
        paddingLeft: "2%",
        minWidth: "60vw",
    },
    iconButton: { 
        color: "white", 
        background: "#246480",
        '&:hover': {
            background: "#246480",
          } 
    },
    iconButtonListening: { 
        color: "white",
        background: "#00ACF5",
        boxShadow: "0px 0px 10px 4px #00ACE0",
        '&:hover': {
            background: "#00ACF5",
          }
        
    }
};

export default styles;
