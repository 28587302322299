const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        //justifyContent: 'space-between',
        alighItems: 'center',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        marginBottom: '1.2rem',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    small: {
        fontSize: 'small',
        color: '#929292'
    },
    centerText: {
        display: 'flex',
        alignItems: 'center',
        width: "max-width",
    },
    icon: {
        width: "42px", 
        height: "42px"
    }
}

export default styles