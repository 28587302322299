import { Button, Typography } from "@mui/material";
import { useGetAllCoPilotsAssigned } from "hooks/CoPilots";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles";

export default function CoPilotsCarousel() {
    const navigate = useNavigate();
    const [coPilotsList, setCoPilotsList] = useState<any>({});
    const onSuccessFetch = (data: any) => {
        setCoPilotsList(data[0]);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetAllCoPilotsAssigned({
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });
    return (
        <div>
            <Typography sx={styles.title}>{coPilotsList?.title}</Typography>
            <Typography sx={styles.subTitle}>{coPilotsList?.sub_title}</Typography>

            <Button
                variant="outlined"
                style={styles.startBtn}
                onClick={() => navigate(`/aicopilot/${coPilotsList?.id}`)}
            >
                Get started
            </Button>
        </div>
    );
}
