import { FormTypes } from "constants/enum.constants";
import FormBuilder from "pages/adminV2/Components/FormBuilder";
import { forwardRef } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import "./Unpacking.css";

const CreateVsetUnpacking = forwardRef(function CreateVsetUnpacking() {
    const { childRef }: any = useOutletContext();

    const [searchParams] = useSearchParams();
    const surveyName = searchParams.get("title");
    const id = searchParams.get("id");
    const isView = searchParams.get("isView") === "true";

    /// Survey ID
    // const { id } = useParams();

    return (
        <div className="vSetUnpacking">
            <FormBuilder
                formType={FormTypes.unpacking}
                isCreate={true}
                isEdit={false}
                isView={isView}
                surveyName={surveyName}
                surveyID={id}
                childRef={childRef}
            />
        </div>
    );
});

export default CreateVsetUnpacking;
