// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, CssBaseline, IconButton } from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "Form",
        url: "/v2/admin/forms/create",
    },
    {
        name: "Assign",
        url: "/v2/admin/forms/create/assign",
    },
];

function CreateSurveyLayout() {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = parseInt(searchParams.get("id"));
    const isCreate = searchParams.get("isCreate");
    const isEdit = searchParams.get("isEdit");
    const isView = !!searchParams.get("isView") && searchParams.get("isView") === "true";
    const childRef = useRef<any>();
    const [noOfUsers, setNoOfUsers] = useState([0, 1, 2, 3, 4, 5, 6].map((value) => ({ value: value, label: value })));
    const [selectedNoOfUsers, setSelectedNoOfUsers] = useState(null);
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });
    const location = useLocation();
    const navigate = useNavigate();

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}&isEdit=${isEdit}`);
    };

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };
        setActiveTab(tab);
    }, []);

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                childRef.current.addSection();
            }
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="Admin create form"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input className="titleInput" value={title ?? ""} disabled />

                <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={TrashIcon} alt="Delete" />
                </IconButton>
            </Box>

            {/* <Box sx={{ ...styles.container, alignItems: "center" }}>
                <FormControlLabel control={<Checkbox />} label="Allow users to complete form more than 1 time" />
                <Box sx={{ ...styles.selectStyles }}>
                    <ReactSelect
                        data={noOfUsers}
                        label={"Select 0-6 (Default is 1)"}
                        defaultValue={selectedNoOfUsers}
                        setValue={setSelectedNoOfUsers}
                        isDisabled={false}
                    />
                </Box>
            </Box> */}

            <div style={{ marginTop: "10px" }}>
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} onTabSelect={onTabSelect} />{" "}
            </div>

            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet context={{ childRef }} />
            </Box>
        </Box>
    );
}

export default CreateSurveyLayout;
