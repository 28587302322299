import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Styles

// Constants

// Material Imports
import Grid from "@material-ui/core/Grid";

// Static Components
import CircularLoadingProgress from "components/CircularProgress";
import { SwalConstants } from "constants/swal.constants";
import NetworkError from "pages/error/networkError";

// Dynamic Imports
import VSBox from "components/VSBox";

// Utils Import
import { Typography } from "@mui/material";
import { CurrentUserSelector } from "app/slices/UserSlice";
import VSBorderLinearProgress from "components/VSBorderLinearProgress";
import { APIMethodConstants } from "constants/api.method.constants";
import { useFormDataPrevNextQuery, useSaveUnpackingFormAnswersMutation, useUserFormData } from "hooks/surveyFormData";
import { uniq } from "lodash";
import { useEffect } from "react";
import { SwalAlert } from "utils/swal-utils";
import VignetteTextDisplay from "../parkingLot/vignetteTextDisplay";
import { UnpackingElements } from "./UnpackingElements";
import UnpackingStepperProgress from "./UnpackingStepperProgress";
import styles from "./styles";

const UnpackingSurveyQuestions = ({
    refreshNextClusterStep,
    formType,
    surveyID,
    sectionId,
    sectionOrder,
    clusterStepId,
    clusterId,
    vignette_id,
    vignette_text,
    vignette_rank_category,
    setSurveyPrevNextData,
}: any) => {
    const [isData, setIsData] = useState(false);
    const [selectedSurveySectionDetail, setSelectedSurveySectionDetail] = useState<any>([]);
    const [selectedSurveyQuestions, setSelectedSurveyQuestions] = useState<any>([]);
    const [formError, setFormError] = useState([]);
    const [currentSection, setCurrentSection] = useState({ order: sectionOrder, id: sectionId });

    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const [isListening, setIsListening] = useState(false);

    const currentUser = useSelector(CurrentUserSelector);
    const dispatch = useDispatch();

    const hiddenInputRef = useRef<any>(null);

    const urlChangeHandler = (url: any, replace = false) => {
        const historyMethod = replace ? "replaceState" : "pushState";
        window.history[historyMethod];
    };

    const handleValue = (event: any, item: { id: any; answer_type: any }) => {
        if (formError.length) {
            const filtered = formError.filter((error: any) => error.id !== item.id);
            setFormError(filtered);
        }
        let answerValue: any;
        switch (item?.answer_type) {
            case "text":
            case "textarea":
            case "number":
            case "dropdown":
            case "radio":
            case "state":
                answerValue = event.target.value;
                break;
            case "date":
            case "time":
                answerValue = event;
                break;
            case "checkbox": {
                const value = event.target.value;
                try {
                    const questionItem = selectedSurveyQuestions.find((el: any) => el.id === item.id);
                    const checkedValues = questionItem && questionItem["answer"] ? questionItem["answer"] : [];
                    answerValue = uniq([...checkedValues, value]);
                } catch {
                    //
                }
                break;
            }

            case "section":
            default:
        }
        const updatedSurveyQuestionsData = selectedSurveyQuestions.map((question: any) => {
            if (question.id === item.id) {
                question["answer"] = answerValue;
            }
            return question;
        });
        setSelectedSurveyQuestions(updatedSurveyQuestionsData);
    };

    const handleSpeechToText = (item: { id: any }, answerValue: any) => {
        if (formError.length) {
            const filtered = formError.filter((error: any) => error.id !== item.id);
            setFormError(filtered);
        }
        const updatedSurveyQuestionsData = selectedSurveyQuestions.map((question: { [x: string]: any; id: any }) => {
            if (question.id === item.id) {
                question["answer"] = answerValue;
            }
            return question;
        });
        setSelectedSurveyQuestions(updatedSurveyQuestionsData);
    };

    // Render Form Elements in View Mode
    const renderViewElements = ({ item, index }: any) => {
        const isRequired = item.is_required === 1;
        let hasError = false;
        if (formError.length && formError.some((error: any) => error.id === item.id)) {
            hasError = true;
        }
        return UnpackingElements({
            item,
            handleValue,
            handleSpeechToText,
            isRequired,
            hasError,
            vignette_rank_category,
            isListening,
            setIsListening,
        });
    };

    const onSurveyQuestionsDataFetchSuccess = (data: string | any[]) => {
        // We set the data variable and make the setIsData variable to true signifying that the data has been set
        if (data && data.length > 0) {
            const { id, section_title, questions, description } = data[0];
            setSelectedSurveyQuestions(questions);
            setSelectedSurveySectionDetail({ id, section_title, description });
            setIsData(true);
        }
    };

    const onSurveyQuestionsDataFetchError = () => {
        // TODO:- Perform any operation in case of errors while fetching Survey Data
        //
    };

    // get current section questions
    const {
        isFetched,
        isFetching: isSurveyQuestionsDataFetching,
        isError: isSurveyQuestionsDataError,
        data: allSurveyQuestionsData,
        error,
        refetch: refetchSurveyQuestionsData,
    } = useUserFormData({
        surveyID,
        formType,
        sectionId: currentSection.id,
        clusterId,
        clusterStepId,
        isEnabled: !!surveyID,
        vignette_id: vignette_id,
        onSuccess: onSurveyQuestionsDataFetchSuccess,
        onError: onSurveyQuestionsDataFetchError,
    });

    const surveyPrevNextDataFetchSuccess = (data: any) => {
        if (data && Object.keys(data).length > 0) {
            setSurveyPrevNextData(data);
        }
    };

    // get prev and next sections from the current survey section
    const {
        isFetching: isSurveyPrevNextFetching,
        isError: isSurveyPrevNextError,
        data: surveyPrevNextData,
        refetch: refetchSurveyPrevNext,
    } = useFormDataPrevNextQuery({
        surveyID,
        formType,
        onSuccess: surveyPrevNextDataFetchSuccess,
        sectionOrder: currentSection.order,
        isEnabled: !!surveyID,
    });

    const { mutate: saveUnpackingFormQuestionAswers, isLoading: isUnpackingSaving } =
        useSaveUnpackingFormAnswersMutation();

    const handleBackButton = () => {
        const res = confirm("Exit Page? Changes you made may not be saved.");
        if (!res) {
            return;
        }
    };

    const enterValuesInHiddenInput = () => {
        hiddenInputRef.current?.onclick;
    };

    useEffect(() => {
        urlChangeHandler(window.location.href);
        enterValuesInHiddenInput();
        return () => {
            handleBackButton();
        };
    }, []);

    useEffect(() => {
        enterValuesInHiddenInput();

        const handlePopState = (event: any) => {
            event.preventDefault();
            const res = confirm("Exit Page? Changes you made may not be saved.");
            if (res) {
                location.reload();
            }
        };
        window.addEventListener("popstate", handlePopState);
        window.history.pushState({ modalOpened: false }, "");

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [hiddenInputRef, urlChangeHandler]);

    useEffect(() => {
        // set 1st question
        if (selectedSurveyQuestions?.length > 0) {
            if (!currentQuestion?.question_number) {
                setCurrentQuestion(selectedSurveyQuestions[0]);
            }
        }
    }, [selectedSurveyQuestions]);

    useEffect(() => {
        refetchSurveyQuestionsData();
        refetchSurveyPrevNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSection]);

    // We check if the data is fetched and hasn't be set yet to set the data
    // This is to ensure that we set the data with background refetch but don't cause an infinite loop of react setState
    if (isFetched && !isData) {
        if (allSurveyQuestionsData && allSurveyQuestionsData.length > 0) {
            const { id, section_title, questions } = allSurveyQuestionsData[0];
            setSelectedSurveyQuestions(questions);
            setSelectedSurveySectionDetail({ id, section_title });
            setIsData(true);
        }
    }

    const getFormattedPostData = () => {
        let postData: any[] = [];
        selectedSurveyQuestions.slice().forEach((eachQuestion: { [x: string]: any }) => {
            postData = [
                ...postData,
                {
                    answer: eachQuestion["answer"],
                    answer_type: eachQuestion["answer_type"],
                    question_id: eachQuestion["id"],
                },
            ];
        });
        return postData;
    };

    // TO DO: remove and add is required check on Next/Submit
    const validateAnswerData = () => {
        let valid = false;
        const message = "";
        const requiredUnAnsweredQuestions = selectedSurveyQuestions.filter(
            (eachQuestion: { is_required: any; answer: any }) => eachQuestion.is_required && !eachQuestion.answer
        );
        if (requiredUnAnsweredQuestions && requiredUnAnsweredQuestions.length > 0) {
            SwalAlert({
                icon: SwalConstants.error,
                title: "Please complete all required fields.",
                description: "",
            });
            valid = false;
            setFormError(requiredUnAnsweredQuestions);
        } else {
            valid = true;
        }
        return { valid, message };
    };

    const saveAnswers = (finalQuestion: boolean, onSuccessCallback: () => void) => {
        saveUnpackingFormQuestionAswers(
            {
                data: {
                    container_id: surveyID,
                    form_type: formType,
                    user_id: currentUser.sub,
                    cluster_step_id: clusterStepId,
                    completed_step_id: selectedSurveySectionDetail.id,
                    data: getFormattedPostData(),
                    cluster_id: clusterId,
                    vignette_id: vignette_id,
                    finalSection: finalQuestion,
                },
                apiMethodType: APIMethodConstants.post,
            },
            {
                onSuccess: () => {
                    onSuccessCallback();
                },
            }
        );
    };

    // Function to handle submit Form
    const handleClusterStepSubmit = async (type: string) => {
        // last question submitted
        if (currentQuestion.question_number == selectedSurveyQuestions.length) {
            saveAnswers(true, () => location.reload());
            return;
        }

        //search for next question
        const nextQuestion = selectedSurveyQuestions.find(
            (question: any) => question.question_number == currentQuestion.question_number + 1
        );
        if (nextQuestion) {
            saveAnswers(false, () => setCurrentQuestion(nextQuestion));
        }

        return;
    };

    /// We check if there is any data being fetched (In Foreground or Background) to display `CircularLoadingProgress`
    if (isSurveyQuestionsDataFetching || isSurveyPrevNextFetching) {
        return <CircularLoadingProgress />;
    }

    // We check if there is an error present while fetching all Questions of a Survey and display `NetworkError`
    if (isSurveyQuestionsDataError) {
        return <NetworkError handleOnRefresh={refetchSurveyQuestionsData} errorText={error} />;
    }

    const handleBackClick = (previousQuestionNumber: number) => {
        // go to previous question if exists
        const prevQuestion = selectedSurveyQuestions.find(
            (question: any) => question.question_number == previousQuestionNumber
        );
        if (prevQuestion) {
            setCurrentQuestion(prevQuestion);
            return;
        }

        // else return to unpacking detail page on refersh
        const res = confirm("Exit Page? Changes you made may not be saved.");
        if (res) {
            window.location.reload();
        }
    };

    return (
        <>
            <Grid container spacing={2} px={1}>
                <button ref={hiddenInputRef} style={{ visibility: "hidden" }}>
                    Test
                </button>
                {/* <Typography>Explain why the vignette below is considered <b>{vignette.user_rank_category}.</b></Typography> */}
                <Grid item xs={2}>
                    <Typography variant="body2" sx={styles.completionStatus}>
                        {currentQuestion?.question_number}/{selectedSurveyQuestions?.length} questions
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <VSBorderLinearProgress
                        variant="determinate"
                        value={(currentQuestion?.question_number / selectedSurveyQuestions?.length) * 100}
                    />
                </Grid>
            </Grid>
            <VignetteTextDisplay
                vignette_text={vignette_text}
                customStyles={{
                    boxShadow: "0 5px 10px 0px rgba(41, 171, 226, 0.3)",
                }}
                showDragIcon={false}
            />
            <Grid container>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                    {currentQuestion &&
                        currentQuestion.question_number &&
                        renderViewElements({ item: currentQuestion, index: currentQuestion.question_number })}
                    {/* {selectedSurveyQuestions?.map((item: any, index: any) => renderViewElements({ item, index }))} */}
                    {/* {renderViewElements({ item: selectedSurveyQuestions[currentQuestion], index: currentQuestion })} */}
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse", marginTop: "50px" }}>
                    <VSBox sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}>
                        <UnpackingStepperProgress
                            totalSteps={surveyPrevNextData.total}
                            order={surveyPrevNextData.current - 1}
                            handleNext={() => handleClusterStepSubmit("next")}
                            handleBack={handleBackClick}
                            nextText={surveyPrevNextData.next ? "Next" : "Submit"}
                            loading={isUnpackingSaving}
                            totalQuestions={selectedSurveyQuestions?.length ?? 0}
                            currentQuestion={currentQuestion}
                        />
                    </VSBox>
                </Grid>
            </Grid>
        </>
    );
};
export default UnpackingSurveyQuestions;
