import { useMemo } from "react";

// Material UI Components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { IconButton, Typography } from '@mui/material';
import TrashIcon from "assets/icons/trash-red.svg";
import VSAlert from "components/VSAlert";
import SectionFooter from "../SectionFooter";
import './SectionInput.css';

// Material Drag Icon

// `SectionInput` is the function to show the user Text Field Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. duplicateElement -> Represents the function to handle duplicating the Form Element
const SectionInput = (props: any) => {
    const { index, totalSection, item, handleSectionValue, arrayMoved, deleteSec } = props;
    const { section_title, description } = item;
    const hasMultipleSection = totalSection > 1;
    
    return useMemo(
        () => (
            <Paper sx={{ marginTop: "2rem", borderRadius: "10px" }} elevation={0} key={index + 1}>
                <VSAlert
                    icon={false}
                    severity="info"
                    sx={(theme) => ({
                        color: "#fff",
                        background: "#29ABE2",
                        height: hasMultipleSection ? "auto" : "0.5rem",
                        borderBottomLeftRadius: "none",
                        borderBottomRightRadius: "none"
                    })}
                >
                    {hasMultipleSection && `Section ${index + 1} of ${totalSection}`}
                </VSAlert>
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={1}>
                        <Grid display="flex" gap="1rem" alignItems="center" sx={{ paddingBottom: "1rem" }}>
                            <Typography>Add a title and description</Typography>
                            <IconButton sx={{ width: "42px", height: "42px" }} onClick={() => deleteSec({ index, section_title: item.section_title, section_id: item.id })}>
                                <img src={TrashIcon} alt="Delete" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} gap={"2rem"} display={"flex"} flexDirection={"column"}>
                            <TextField
                                placeholder="VSet name"
                                value={item.section_title}
                                variant="outlined"
                                onChange={(e) => handleSectionValue({ e, index })}
                                fullWidth
                                required
                                sx={{ mb: 2}}
                                InputProps={{
                                    sx: {
                                        borderRadius: "1rem",
                                        padding: "6px 1rem",
                                        fontSize: "14px",
                                    }
                                }}
                                multiline
                            />
                            <TextField
                                placeholder="Description"
                                value={item.description || ""}
                                variant="outlined"
                                onChange={(e) => handleSectionValue({ e, index, isDescription: true })}
                                fullWidth
                                sx={{ mb: 2}}
                                InputProps={{
                                    sx: {
                                        borderRadius: "1rem",
                                        padding: "6px 1rem",
                                        fontSize: "14px",
                                    }
                                }}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Box>
                <SectionFooter {...props} />
            </Paper>
        ),
        [section_title, description, totalSection, arrayMoved]
    );
};

export default SectionInput;
