import TextField from "@material-ui/core/TextField";
import { Box, Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import VSAlert from "components/VSAlert";
import { useGetAllCoPilotEngines, useGetSpecificCoPilot, useSavingCoPilot } from "hooks/CoPilots";
import { DynamicModel } from "pages/adminV2/Components";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import "../../../Survey/models/DeleteSurvey/DeleteSurvey.css";
import styles from "./styles";
import "./styles.css";

const dropDownStyle = {
    dropdown: {
        outlineWidth: 0,
        borderRadius: "9px",
        border: "none",
        padding: "0",
        "& .MuiSelect-select": {
            padding: "6px",

            // paddingLeft: "15px",
        },
    },
};
const CreateCoPilot = forwardRef(function CreateCoPilot() {
    const { childRef }: any = useOutletContext();

    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");
    const isCreate = searchParams.get("isCreate");

    const [engineList, setEngineList] = useState([]);
    const [selectedEngine, setSelectedEngine] = useState({});
    const [open, setOpen] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [coPilotData, setCoPilotData] = useState({
        title: "",
        subTitle: "",
        directions: "",
        promptMessage: "",
        selectedEngine: "",
    });

    useEffect(() => {
        refetchEngineList();
    }, []);

    const onEngineListDataFetchSuccess = (data: any) => {
        setEngineList(data);
    };
    const onEngineListDataFetchError = (error: any) => {
        undefined;
    };

    const {
        isFetched: isEngineListFetched,
        isFetching: isEngineListDataFetching,
        isLoading: isEngineListDataLoading,
        isError: isEngineListDataError,
        data: engineListData,
        error: engineListDataError,
        refetch: refetchEngineList,
    } = useGetAllCoPilotEngines({
        onSuccess: onEngineListDataFetchSuccess,
        onError: onEngineListDataFetchError,
    });

    const handleEngineSelection = (event: any) => {
        setSelectedEngine(event);
    };

    const handleChange = (event: any, type: any) => {
        const data = event.target.value;

        switch (type) {
            case "subTitle":
                setCoPilotData({ ...coPilotData, subTitle: data });
                break;
            case "directions":
                setCoPilotData({ ...coPilotData, directions: data });
                break;
            case "promptMessage":
                setCoPilotData({ ...coPilotData, promptMessage: data });
                break;
            case "selectedEngine":
                setCoPilotData({ ...coPilotData, selectedEngine: data });
        }
    };
    const onSuccessFetch = (data: any) => {
        setCoPilotData({
            ...coPilotData,
            title: data?.title,
            subTitle: data?.sub_title,
            directions: data?.directions,
            promptMessage: data?.prompt_message,
            selectedEngine: data?.engine_id,
        });
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetSpecificCoPilot({
        coPilotID: id,
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const onSaveSuccess = (data: any) => {
        navigate("/v2/admin/copilots");
    };
    const onSaveError = (data: any) => {
        undefined;
    };

    const {
        mutate: callingCopilot,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useSavingCoPilot({
        data: coPilotData,
        coPilotID: id,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const callAICoPilot = async () => callingCopilot();

    const handleSubmit = async () => {
        await callAICoPilot();
    };

    useImperativeHandle(childRef, () => {
        return {
            save() {
                if (
                    coPilotData?.subTitle?.length != 0 &&
                    coPilotData?.directions?.length != 0 &&
                    coPilotData?.promptMessage?.length != 0 &&
                    coPilotData?.selectedEngine?.length != 0
                ) {
                    setOpen(true);
                } else {
                    setWarningOpen(true);
                }
            },
        };
    });

    return (
        <div>
            <Paper sx={{ marginTop: "0.5rem", borderRadius: "10px" }} elevation={0} key={2}>
                <VSAlert
                    icon={false}
                    severity="info"
                    sx={(theme) => ({
                        color: "#fff",
                        background: "#29ABE2",
                        height: "0.5rem",
                        borderBottomLeftRadius: "none",
                        borderBottomRightRadius: "none",
                    })}
                ></VSAlert>
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={1}>
                        <Grid display="flex" gap="1rem" alignItems="center" sx={{ padding: "1rem" }}>
                            <Typography>Add a CoPilot subtitle and directions</Typography>
                        </Grid>
                        <TextField
                            placeholder="Subtitle"
                            value={coPilotData?.subTitle}
                            variant="outlined"
                            onChange={(e) => handleChange(e, "subTitle")}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                            style={{
                                padding: "1rem",
                            }}
                            InputProps={{
                                sx: {
                                    borderRadius: "5px",
                                    borderColor: "black",
                                    padding: "6px 1rem",
                                    fontSize: "14px",
                                },
                            }}
                            multiline
                        />

                        <TextField
                            placeholder="Directions"
                            value={coPilotData?.directions}
                            variant="outlined"
                            onChange={(e) => handleChange(e, "directions")}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                            style={{
                                padding: "0 1rem 1rem 1rem",
                            }}
                            InputProps={{
                                sx: {
                                    borderRadius: "5px",
                                    borderColor: "black",
                                    padding: "6px 1rem",
                                    fontSize: "14px",
                                },
                            }}
                            multiline
                        />
                        {/* <Typography
                            // onClick={() => addElement({ index })}
                            style={{ color: "#29ABE2", cursor: "pointer", margin: "10px 1rem" }}
                        >
                            Add dates
                        </Typography> */}
                    </Grid>
                </Box>
            </Paper>
            <Box sx={styles.container}>
                <Grid container gap={"2rem"} display={"flex"} spacing={1}>
                    <Grid item xs={3} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Typography sx={styles.fieldInputLabel}>Select CoPilot AI</Typography>
                        <Select
                            value={coPilotData?.selectedEngine}
                            onChange={(event) => handleChange(event, "selectedEngine")}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            fullWidth
                            sx={[dropDownStyle.dropdown, dropDownStyle]}
                            placeholder="Select AI Engine"
                        >
                            <MenuItem key={1} value={""} disabled selected>
                                Select AI Engine
                            </MenuItem>

                            {engineList.map((option: any, index) => {
                                return (
                                    <MenuItem key={index} value={option.value}>
                                        {option.text}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {/* <AdminSelect
                            options={engineList}
                            selectedValue={selectedEngine}
                            onChange={handleEngineSelection}
                            placeholder="Select AI Engine"
                        /> */}
                    </Grid>
                    <Grid item xs={8} display={"flex"} flexDirection={"column"} gap={"10px"}>
                        <Typography sx={styles.fieldInputLabel}>CoPilot prompt</Typography>
                        <TextField
                            placeholder="Type a prompt here"
                            value={coPilotData?.promptMessage}
                            variant="outlined"
                            onChange={(e) => handleChange(e, "promptMessage")}
                            fullWidth
                            required
                            sx={{ mb: 2 }}
                            InputProps={{
                                sx: {
                                    borderRadius: "5px",
                                    borderColor: "black",
                                    padding: "7px 1em",
                                    fontSize: "14px",
                                },
                            }}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Box>
            {open && (
                <DynamicModel open={open} setOpen={setOpen} title={`Are you sure you want to save?`}>
                    <div className="deleteModel">
                        <div className="deleteModelContainer">
                            <button onClick={() => handleSubmit()}>Save Form</button>
                        </div>
                    </div>
                </DynamicModel>
            )}

            {warningOpen && (
                <DynamicModel open={warningOpen} setOpen={setWarningOpen} title={`Please fill all the fields.`}>
                    <div className="deleteModel">
                        <div className="deleteModelContainer">
                            <button onClick={() => setWarningOpen(false)}>Okay</button>
                        </div>
                    </div>
                </DynamicModel>
            )}
        </div>
    );
});

export default CreateCoPilot;
