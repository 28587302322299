import { useMutation, useQuery, useQueryClient } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

import { DemographicRequestBody } from "types/dashboard";

const fetchUserProfile = () => {
    return request({ url: NetworkConstants.getProfile, method: APIMethodConstants.get });
};

const fetchAllUsers = () => {
    return request({ url: NetworkConstants.getAllUsers, method: APIMethodConstants.get });
};

const fetchUserPermissions = () => {
    return request({ url: NetworkConstants.getPermissions, method: APIMethodConstants.get });
};

const postSendEmail = ({ bodyData }: any) => {
    return request({
        url: NetworkConstants.sendEmail,
        method: APIMethodConstants.post,
        data: bodyData,
    });
};

const allDemographicsOptions = () => {
    return request({
        url: NetworkConstants.getAllDemographicsOptions,
        method: APIMethodConstants.get,
    });
};

export const getAllDemographicsOptions = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(QueryKeyConstants.fetchAllDemographics, allDemographicsOptions, {
        enabled: isEnabled,
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

const demographicsData = () => {
    return request({
        url: NetworkConstants.demographicsData,
        method: APIMethodConstants.get,
    });
};

export const getDemographicsData = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(QueryKeyConstants.fetchUserDemographics, demographicsData, {
        enabled: isEnabled,
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const updateUserDemographics = ({ data }: { data: DemographicRequestBody }) => {
    return request({
        url: NetworkConstants.demographicsData,
        method: APIMethodConstants.put,
        data,
    });
};

export const getUserProfile = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(QueryKeyConstants.getProfile, fetchUserProfile, {
        enabled: isEnabled,
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};
// useFormData -> To fetch Questions of a particular Survey (Pre-Survey, Post-Survey and Final Feedback)
export const useUserListData = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery([QueryKeyConstants.fetchAllUsersQuery], () => fetchAllUsers(), {
        enabled: isEnabled,
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

// useSendEmail -> To send emails
export const useSendEmail = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ data }: any) => {
            return postSendEmail({ bodyData: data });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QueryKeyConstants.sendEmail);
            },
            onError: () => {
                queryClient.invalidateQueries(QueryKeyConstants.sendEmail);
            },
        }
    );
};

export const useGetUserPermissions = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(QueryKeyConstants.getPermissions, fetchUserPermissions, {
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};
