import { Box, Popover, Typography } from "@mui/material";
import Logo from "assets/images/vsorts-logo.png";
import { getUserProfile } from "hooks/userData";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { User } from "types/user";

import { CurrentUserSelector } from "app/slices/UserSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserRole } from "utils/userHelper";
import UserProfilePopover from "../UserProfilePopover";
import styles from "./styles";

type PropsType = {
    user: User | null;
};

export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [user, setUser] = React.useState<any | null>(null);
    const currentUser = useSelector(CurrentUserSelector);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const onUserDataFetchSuccess = (data: any) => {
        setUser({
            id: (data?.user_id as string) || "",
            name: (data?.name as string) || "",
            nickname: (data?.nickname as string) || "",
            img: data?.picture || currentUser?.picture || "",
            email: (data?.email as string) || (currentUser?.email as string) || "",
            first_name: (data?.first_name as string) || "",
            last_name: (data?.last_name as string) || "",
            role: getUserRole(data?.roles as string[]),
        });
    };

    const onUserDataFetchError = (error: any) => {
        setUser(null);
    };

    const {
        isFetching: isProfileDataFetching,
        isLoading: isProfileDataLoading,
        isError: isProfileDataError,
        data: profileData,
        error: profileDataError,
        refetch: refetchUserProfile,
    } = getUserProfile({
        onSuccess: onUserDataFetchSuccess,
        onError: onUserDataFetchError,
    });

    // const fetchUserProfile = async () => {
    //     // try {
    //     const res: any = await getUserProfile();
    //     const data: any = res?.data?.data;
    //     setUser({
    //         id: (data?.user_id as string) || "",
    //         name: (data?.name as string) || "",
    //         nickname: (data?.nickname as string) || "",
    //         img: data?.picture || currentUser?.picture || "",
    //         email: (data?.email as string) || (currentUser?.email as string) || "",
    //         first_name: (data?.first_name as string) || "",
    //         last_name: (data?.last_name as string) || "",
    //         role: getUserRole(data?.roles as string[]),
    //     });
    //     // } catch (err) {
    //     // }
    // };

    useEffect(() => {
        // fetchUserProfile();
        refetchUserProfile();
    }, [location.pathname]);

    const navigateToDashboard = () => {
        navigate(`/dashboard`);
    };

    const redirectToStripe = () => {
        navigate(`/stripe`);
    };

    return (
        <Box sx={styles.container}>
            <Link to="/dashboard">
                <img src={Logo} alt="Logo" style={styles.logo} />
            </Link>
            <Box sx={styles.flexContainer}>
                <Typography sx={styles.plansPricingButton} onClick={redirectToStripe}>
                    Plans & pricing
                </Typography>
                <Typography sx={styles.title} onClick={navigateToDashboard}>
                    Dashboard
                </Typography>
                <button aria-describedby={id} onClick={handleClick} style={styles.avatarButton}>
                    <img src={user?.img} alt="Logo" style={styles.avatar} />
                </button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <UserProfilePopover user={user} />
                </Popover>
            </Box>
        </Box>
    );
}
