import { Button as MUIButton } from "@mui/material";
import { CSSProperties } from "react";
import styles from "./styles";

export type PropsType = {
    text: string;
    type: "primary" | "primaryOutlined" | "secondary" | "normal" | "active";
    style?: CSSProperties;
    onClick: () => void;
    disabled?: boolean;
};

const Button = ({ text, type, style = {}, onClick, disabled = false }: PropsType) => {
    return (
        <>
            {disabled ? (
                <MUIButton
                    type="button"
                    sx={[styles.button, styles.disabled, style]}
                    onClick={onClick}
                    // disabled={disabled}
                >
                    {text}
                </MUIButton>
            ) : (
                <MUIButton
                    type="button"
                    sx={[
                        styles.button,
                        type === "primary" && styles.primary,
                        type === "secondary" && styles.secondary,
                        type === "active" && styles.active,
                        type === "primaryOutlined" && styles.primaryOutlined,
                        style,
                    ]}
                    onClick={onClick}
                >
                    {text}
                </MUIButton>
            )}
        </>
    );
};

export default Button;
