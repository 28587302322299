const individualStyles: any = {
    title: {
        fontWeight: "700",
        fontSize: "18px",
        color: "#111111",
        // lineHeight: "34px",
        // marginTop: "3vh",
    },
    subTitle: {
        fontWeight: "400",
        fontSize: "14px",
        color: "#111111",
        lineHeight: "26px",
        marginTop: "3vh",
        marginBottom: "3vh",
    },
    startBtn: {
        width: "100%",
        height: "42px",
        color: "#29ABE2",
        borderColor: "#29ABE2",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        lineHeight: "19.5px",
        padding: "10px, 24px, 10px, 24px",
        gap: "8px",
        borderWidth: "2px",
    },
    newBtn: {
        color: "#009245",
        borderColor: "#009245",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "17.07px",
        borderRadius: "25px",
        padding: "5px, 15px, 5px, 15px",
        gap: "10px",
        textTransform: "none",
        marginBottom: "10px",
    },
};
export default individualStyles;
