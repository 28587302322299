import { Box, TablePagination, Typography } from "@mui/material";
import { useVSetClusters } from "hooks/VSortsContainerData";
import { useUserFormResponsesData } from "hooks/surveyFormData";
import { Button } from "pages/adminV2/Components";
import ReactSelect from "pages/adminV2/Components/Select";
import { useState } from "react";
import { uid } from "react-uid";
import FormResponsePanel from "./FormResponsePanel/FormResponsePanel";

const ViewFormResponse = () => {
    const org_id = "org_NYZu26HrSYyyrbD6";
    const form_type = "presurvey";
    const params = new URLSearchParams(location.search);
    const formId = params.get("formId");
    const [clustersOptions, setClusterOptions] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState<any>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formTitle, setFormTitle] = useState("");

    const handleExit = () => {
        undefined;
    };

    const {
        data: formResponse,
        isLoading: isSurveyDataLoading,
        isError: isSurveyDataError,
    } = useVSetClusters({
        org_id,
        form_type,
        container_id: formId,
        onSuccess: (data: any) => {
            const clusterData = data.map((cluster: any) => ({
                value: cluster.cluster_id,
                label: cluster.cluster_name,
            }));
            setClusterOptions(clusterData);

            setSelectedCluster(clusterData[0]);
        },
        onError: (error: any) => {
            console.error("Error fetching data:", error);
        },
        isEnabled: true,
    });

    const { data, error, isLoading, isError } = useUserFormResponsesData({
        containerId: formId,
        clusterId: selectedCluster?.value || 204,
        onSuccess: (data: any) => {
            undefined;
            setFormTitle(data[0].container_name);
        },
        onError: (error: any) => {
            console.error("Error fetching data:", error);
        },
        isEnabled: true,
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: "1rem",
                    backgroundColor: "white",
                    borderRadius: "10px",
                }}
            >
                <Typography sx={{ display: "flex", gap: "5px" }}>
                    <Typography sx={{ fontWeight: "bold" }}>{formTitle} </Typography>
                </Typography>
                <Box sx={{ display: "flex", gap: "2rem", alignItems: "center", width: "40%" }}>
                    <Box sx={{ flex: 1 }}>
                        <ReactSelect
                            data={clustersOptions}
                            label={"Cluster"}
                            defaultValue={selectedCluster}
                            setValue={setSelectedCluster}
                            isDisabled={false}
                        />
                    </Box>
                    <Button type="primary" text="Save" onClick={() => handleExit()} />
                </Box>
            </Box>
            <Box sx={{ display: "flex" }}></Box>
            <Box sx={{ marginTop: "2rem", display: "flex", flexDirection: "column", gap: "2rem" }}>
                {data?.map((form: any) => (
                    <FormResponsePanel key={uid(form)} header={form.user_id} content={form.responses} />
                ))}
            </Box>
            {data?.length && (
                <div>
                    <TablePagination
                        component="div"
                        count={data?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </Box>
    );
};

export default ViewFormResponse;
