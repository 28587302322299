import {
    CLUSTER_STATUS_COMPLETED,
    CLUSTER_STATUS_NOT_STARTED,
    CLUSTER_STATUS_PROGRESS,
    CLUSTER_STATUS_RESULTS_PENDING,
    CLUSTER_STATUS_SUBMITTED,
    DASHBOARD_VIEW_MODE_GRID,
    DASHBOARD_VIEW_MODE_LIST,
} from "constants/dashboard.constants";

import { Dayjs } from "dayjs";
import { Dispatch } from "react";
import { User } from "./user";

export enum ClusterStatus {
    NOT_STARTED = CLUSTER_STATUS_NOT_STARTED,
    IN_PROGRESS = CLUSTER_STATUS_PROGRESS,
    RESULTS_PENDING = CLUSTER_STATUS_RESULTS_PENDING,
    COMPLETED = CLUSTER_STATUS_COMPLETED,
    SUBMITTED = CLUSTER_STATUS_SUBMITTED,
}

export type Cluster = {
    id: number;
    title: string;
    url: string;
    status: ClusterStatus;
    completedStatus: string;
    completed: number;
    createdAt: string;
    published: boolean;
    users: User[];
    last_modified: string;
};

export type UserStatus = {
    flaggedVignettes: number;
    unpackedVignettes: number;
    culturalProfiency: number;
};

export type UserClusterStatus = {
    activeClusters: number;
    completedClusters: number;
    coPilotsQueries: string;
};

export enum ViewType {
    VIEW_MODE_GRID = DASHBOARD_VIEW_MODE_GRID,
    VIEW_MODE_LIST = DASHBOARD_VIEW_MODE_LIST,
}

export type ClusterStepData = {
    id: number;
    clusterId: number;
    containerId: number;
    containerName: string;
    deleted: boolean;
    formType: string;
    stepNumber: number;
    completed: number;
};

export type StepIcon = {
    step: number;
    icon: any;
};

export type CountryOption = {
    id: number;
    code: string;
    name: string;
};

export type EducationOption = {
    id: number;
    name: string;
    description: string;
};

export type GenderOption = {
    id: number;
    name: string;
};

export type IncomeOption = {
    id: number;
    currency: string;
    currency_symbol: string;
    min_income: number;
    max_income: number;
};

export type PoliticalOption = {
    id: number;
    name: string;
    description: string;
};

export type RaceOption = {
    id: number;
    name: string;
    description: string;
};

export type RelationshipOption = {
    id: number;
    name: string;
    description: string;
};

export type SexualOrientationOption = {
    id: number;
    name: string;
    description: string;
};

export type StateOption = {
    id: number;
    name: string;
    code: string;
    country_id: number;
};

export type DemographicsOptions = {
    countries: CountryOption[];
    states: StateOption[];
    educations: EducationOption[];
    genders: GenderOption[];
    incomes: IncomeOption[];
    politicalOrientations: PoliticalOption[];
    races: RaceOption[];
    relationshipStatuses: RelationshipOption[];
    sexualOrientations: SexualOrientationOption[];
};

export type DemographicsData = {
    date_of_birth: string;
    country_id: number;
    country_name: string;
    country_code: string;
    state_id: number;
    state_name: string;
    state_code: string;
    state_country_id: number;
    race_id: number;
    race_name: string;
    race_description: string;
    gender_id: number;
    gender_name: string;
    relationship_status_id: number;
    relationship_status_name: string;
    relationship_status_description: string;
    sexual_orientation_id: number;
    sexual_orientation_name: string;
    sexual_orientation_description: string;
    education_id: number;
    education_name: string;
    education_description: string;
    income_id: number;
    min_income: number;
    max_income: number;
    currency: string;
    currency_symbol: string;
    political_orientation_id: number;
    political_orientation_name: string;
    political_orientation_description: string;
};

export type AdminCluster = {
    user_id: number;
    cluster_id: number;
    score: number;
};

export type DatePickerProps = {
    defaultValue: Dayjs | null;
    value: Dayjs | null;
    setValue: Dispatch<Dayjs>;
};

export interface DemographicRequestBody {
    country: number;
    state: number;
    race: number;
    gender: number;
    relationship_status: number;
    sexual_orientation: number;
    education: number;
    income: number;
    political_orientation: number;
    date_of_birth: Date;
}
