import CheckboxInput from './Elements/CheckboxInput';
import DateInput from './Elements/DateInput';
import DropdownInput from './Elements/DropdownInput';
import HeaderInfoInput from './Elements/HeaderInfoInput';
import NumberInput from './Elements/NumberInput';
import RadioInput from './Elements/RadioInput';
import SectionInput from './Elements/SectionInput';
import StateInput from './Elements/StateInput';
import TextArea from './Elements/TextArea';
import TextFieldInput from './Elements/TextFieldInput';
import TimeInput from './Elements/TimeInput';

const Editelements = ({
  item,
  index,
  handler,
  handleValue,
  handleSectionValue,
  deleteEl,
  deleteSec,
  handleRequired,
  handleElType,
  duplicateElement,
  addOption,
  handleOptionValues,
  deleteOption,
  handleDate,
  handleTime,
  totalSection,
  addElement,
  arrayMoved
}: any) => {
  switch (item.answer_type) {
    case "section":
        return (
            <SectionInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                addElement={addElement}
                handleSectionValue={handleSectionValue}
                deleteSec={deleteSec}
                totalSection={totalSection}
                arrayMoved={arrayMoved}
            />
        );
    case "text":
        return (
            <TextFieldInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}

            />
        );
    case "textarea":
        return (
            <TextArea
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "header_info":
        return (
            <HeaderInfoInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleElType={handleElType}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "number":
        return (
            <NumberInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "radio":
        return (
            <RadioInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                addOption={addOption}
                handleOptionValues={handleOptionValues}
                deleteOption={deleteOption}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "checkbox":
        return (
            <CheckboxInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                addOption={addOption}
                handleOptionValues={handleOptionValues}
                deleteOption={deleteOption}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "dropdown":
        return (
            <DropdownInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                addOption={addOption}
                handleOptionValues={handleOptionValues}
                deleteOption={deleteOption}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "date":
        return (
            <DateInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                handleDate={handleDate}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "time":
        return (
            <TimeInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                handleTime={handleTime}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    case "state":
        return (
            <StateInput
                key={item.id}
                item={item}
                index={index}
                handler={handler}
                handleValue={handleValue}
                deleteEl={deleteEl}
                handleRequired={handleRequired}
                handleElType={handleElType}
                duplicateElement={duplicateElement}
                arrayMoved={arrayMoved}
            />
        );
    default:
        return <></>;
}
}

export default Editelements