import { DynamicModel } from "pages/adminV2/Components";
import "./DeleteCluster.css";

interface props {
    open: boolean;
    setOpen: (value: boolean) => void;
    handleDelete: () => void;
}

function DelecteClusterActivityModal({ open, setOpen, handleDelete }: props) {
    const handleDeleteClick = async () => {
       handleDelete();
    };

    return (
        <DynamicModel
            open={open}
            setOpen={setOpen}
            title={`Are you sure you want to delete this cluster activity?`}
        >
            <div className="deleteModal">
                <div className="deleteModalContainer">
                    <p className="deleteModalContainer__desc">You cannot undo this action once you confirm</p>
                    <button onClick={() => handleDeleteClick()}>Delete activity</button>
                </div>
            </div>
        </DynamicModel>
    );
}

export default DelecteClusterActivityModal;
