//import SearchIcon from '@mui/icons-material/Search'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ChartIcon from "assets/icons/chart.svg";
import TimeIcon from "assets/icons/time.svg";
import CircularLoadingProgress from "components/CircularProgress";
import {
    CLUSTER_STATUS_COMPLETED,
    CLUSTER_STATUS_NOT_STARTED,
    CLUSTER_STATUS_PROGRESS,
    CLUSTER_STATUS_RESULTS_PENDING,
    CLUSTER_STATUS_SUBMITTED,
} from "constants/dashboard.constants";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cluster } from "types/dashboard";
import { User } from "types/user";
import styles from "./styles";

type PropsType = {
    currentUser: User | null;
    isLoading: boolean;
    clusters: Cluster[];
    onSelectCluster: (cluster: Cluster) => void;
    setOpenFilter: Dispatch<SetStateAction<boolean>>;
};

const Clusters: React.FC<PropsType> = ({ currentUser, isLoading, clusters, onSelectCluster, setOpenFilter }) => {
    const [tab, setTab] = useState<string>("tasks");
    const navigate = useNavigate();

    const tabClusters: Cluster[] = useMemo(() => {
        return clusters;
        let filteredClusters: Cluster[] = [];
        if (tab === "tasks") {
            filteredClusters = clusters.filter((cluster) => cluster.status !== CLUSTER_STATUS_COMPLETED);
        } else if (tab === "completed") {
            filteredClusters = clusters.filter((cluster) => cluster.status === CLUSTER_STATUS_COMPLETED);
        } else {
            filteredClusters = clusters;
        }

        return filteredClusters;
    }, [tab, clusters]);

    const handleTabSelect = (tabName: string) => {
        setTab(tabName);
    };

    const navigateToClusterDetailPage = (clusterID: number) => {
        navigate(`/user/vsort-cluster/${clusterID}`);
    };

    if (isLoading) {
        return <CircularLoadingProgress />;
    }
    return (
        <Box sx={styles.container}>
            {/* <Box sx={{ ...styles.flexContainer }}>
                <Box sx={{ ...styles.flexContainer }}>
                    <Typography sx={styles.title}>VSorts™ Clusters</Typography>
                    <Button sx={styles.btnSearch}>
                        <img src={SearchIcon} alt="search" />
                    </Button>
                </Box>

                <Box sx={{ ...styles.flexContainer }}>
                    <Box>
                        <IconButton sx={styles.btnTab}>
                            <RefreshIcon sx={{ color: "#29ABE2", rotate: "270deg" }} />
                        </IconButton>
                        <Button
                            disableRipple
                            variant="contained"
                            sx={styles.btnFilter}
                            onClick={() => setOpenFilter((openFilter) => !openFilter)}
                        >
                            <Typography fontSize={"0.88rem"}>
                                Filter by{" "}
                                <ArrowForwardIosIcon fontSize="small" sx={{ color: "#94A8B0", fontSize: "1.1rem" }} />
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box> */}

            <Box sx={{ ...styles.clusters }}>
                {tabClusters.map((cluster, index) => (
                    <Box sx={styles.cluster} onClick={() => onSelectCluster(cluster)} key={index}>
                        <Box sx={styles.clusterInfo}>
                            <Typography sx={styles.clusterTitle}>{cluster.title}</Typography>
                            <Box sx={styles.flexContainer}>
                                {cluster.status === CLUSTER_STATUS_COMPLETED && (
                                    <Box sx={styles.flexContainer}>
                                        <img src={ChartIcon} alt="Chart" style={styles.clusterIcon} />
                                        <Typography sx={styles.resultsText}>&nbsp;View results</Typography>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_NOT_STARTED && (
                                    <Typography sx={styles.pendingText}>Not Started</Typography>
                                )}
                                {cluster.status === CLUSTER_STATUS_PROGRESS && (
                                    <Box sx={styles.flexContainer}>
                                        <Typography
                                            sx={{ ...styles.progressText, color: "#F84444", fontStyle: "none" }}
                                        >
                                            Incomplete&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <img src={TimeIcon} alt="Time" style={styles.clusterIcon} />
                                        <Typography sx={styles.progressText}>
                                            {`${cluster.completedStatus}%`}
                                        </Typography>
                                    </Box>
                                )}
                                {cluster.status === CLUSTER_STATUS_RESULTS_PENDING && (
                                    <Typography sx={styles.pendingText}>Results pending</Typography>
                                )}
                                {cluster.status === CLUSTER_STATUS_SUBMITTED && (
                                    <Typography sx={styles.pendingText}>Results pending</Typography>
                                )}
                            </Box>
                        </Box>
                        {/* Cluster Buttons */}
                        {cluster.status === CLUSTER_STATUS_NOT_STARTED && (
                            // Start Button

                            <Box sx={styles.flexContainer}>
                                <Button sx={styles.btnStart} onClick={() => navigateToClusterDetailPage(cluster.id)}>
                                    Start
                                </Button>
                                <IconButton sx={styles.btnMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        )}
                        {cluster.status === CLUSTER_STATUS_PROGRESS && (
                            // Continue Button
                            <Box sx={styles.flexContainer}>
                                <Button sx={styles.btnContinue} onClick={() => navigateToClusterDetailPage(cluster.id)}>
                                    Continue
                                </Button>
                                <IconButton sx={styles.btnMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        )}
                        {cluster.status === CLUSTER_STATUS_RESULTS_PENDING && (
                            // Submitted Button, result pending
                            <Box sx={styles.flexContainer}>
                                <Button disabled sx={styles.btnSubmitted}>
                                    Submitted
                                </Button>
                                <IconButton sx={styles.btnMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        )}
                        {cluster.status === CLUSTER_STATUS_SUBMITTED && (
                            // Submitted Button, result pending
                            <Box sx={styles.flexContainer}>
                                <Button disabled sx={styles.btnSubmitted}>
                                    Submitted
                                </Button>
                                <IconButton sx={styles.btnMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        )}
                        {cluster.status === CLUSTER_STATUS_COMPLETED && (
                            // Completed Button
                            <Box sx={styles.flexContainer}>
                                <Button disabled sx={styles.btnCompleted}>
                                    Completed
                                </Button>
                                <IconButton sx={styles.btnMore}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Clusters;
