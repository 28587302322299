import { useAuth0 } from "@auth0/auth0-react";
import { setCurrentUser } from "app/slices/UserSlice";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

type PropsType = {
    children: any;
};

// `RequireAuth` is the function to protect any routes that need to be Authentication Protected
// 1. children -> Represents all the children that are going to be Authentication Protected
export default function RequireAuth({ children }: PropsType) {
    const { user, isAuthenticated, error, logout } = useAuth0();
    const cookies = new Cookies();

    const dispatch = useDispatch();
    // This is ued to navigate user to the route which they were present on before being kicked out because of
    // Authentication failure
    const location = useLocation();

    const navigate = useNavigate();
    const currentLocation = location.pathname;

    if (error) {
        console.log("Require auth", error.message);
        if (error.message == "client requires organization membership, but user does not belong to any organization") {
            // logout({ returnTo: window.location.origin });

            navigate("/login");
        } else {
            cookies.remove("idToken");
            logout({ returnTo: window.location.origin });
        }
    }
    // If the user is not Authenticated, we send them back to the '/' route
    if (!user) {
        // return <Navigate to="/login" state={{ path: currentLocation }} />;
        return null;
    }
    dispatch(setCurrentUser(user ? user : null));

    if (isAuthenticated) {
        return children;
    } else {
        return <Navigate to="/login" state={{ path: currentLocation }} />;
    }
}
