import { Box, IconButton, Typography } from "@mui/material";
import DragIcon from "assets/icons/drag.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import AdminSelect from "pages/adminV2/Components/AdminSelect";
import { ClusterStep } from "types/clusters";
import styles from "./styles";

type props = {
    clusterStep: ClusterStep;
    formTypeContainers: (formType: string) => any[];
    handleActivityTypeChange: (step: ClusterStep) => void; //pass updated step,
    handleActivityChange: (step: ClusterStep) => void; //pass updated step,
    handleActivityDelete: (step: ClusterStep) => void;
    isView: boolean;
};

function ClusterStepItem({
    clusterStep,
    formTypeContainers,
    handleActivityTypeChange,
    handleActivityChange,
    handleActivityDelete,
    isView,
}: props) {
    const formTypes = [
        {
            text: "VSets",
            value: "vsorts",
        },
        {
            text: "Unpacking",
            value: "unpacking",
        },
        {
            text: "Form",
            value: "presurvey",
        },
    ];

    // get activities based on activity type
    function formTypesWithOption(formType: string) {
        let formContainers = formTypeContainers(formType);
        return (formContainers = formContainers.map((container: any) => {
            return {
                ...container,
                text: container?.container_name,
                value: container?.id,
            };
        }));
    }

    const handleActivityTypeOnChange = (value: any) => {
        clusterStep.formType = value;
        handleActivityTypeChange(clusterStep);
    };

    const handleActivityOnChange = (value: any) => {
        clusterStep.containerId = value;
        handleActivityChange(clusterStep);
    };

    const handleRemove = () => {
        handleActivityDelete(clusterStep);
    };

    return (
        <>
            <Box sx={styles.container}>
                <IconButton disableRipple>
                    <img src={DragIcon} alt="frag" />
                </IconButton>

                {/* select activity type */}
                <Box sx={{ ...styles.rowContainer, flex: 1 }}>
                    <Typography sx={styles.small}>Select an activity type</Typography>
                    <AdminSelect
                        style={{ padding: "10px" }}
                        placeholder="Select type"
                        options={formTypes}
                        selectedValue={clusterStep.formType}
                        onChange={handleActivityTypeOnChange}
                        readOnly={isView}
                    />
                </Box>

                {/* Select activity */}
                <Box sx={{ ...styles.rowContainer, flex: 3 }}>
                    <Typography sx={styles.small}>Select an activity</Typography>
                    <AdminSelect
                        style={{ padding: "10px" }}
                        placeholder="Select activity"
                        options={formTypesWithOption(clusterStep.formType ?? "")}
                        selectedValue={clusterStep.containerId ?? ""}
                        onChange={handleActivityOnChange}
                        readOnly={isView}
                    />
                </Box>

                {!isView && (
                    <Box sx={{ alignContent: "center" }}>
                        <IconButton sx={styles.icon} onClick={handleRemove}>
                            <img src={TrashIcon} alt="frag" />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default ClusterStepItem;
