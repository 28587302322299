import uuid from "react-uuid";

// Material UI Components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// Material Icons (Drag and Delete)

// Form Element Type Constant

// Form Element Footer

import { Grid, Typography } from '@mui/material';
import SurveyElementWrapper from "../SurveyElementWrapper";

// `CheckboxInput` is the function to show the user Checkbox Input while creating/editing a Survey Form
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. handleValue -> Represents the function to handle the Text Input value
// 3. deleteEl -> Represents the function to handle deleting the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
// 5. handleElType -> Represents the function to handle the type of the Form Element
// 6. addOption -> Represents the function to add an option to the Checkbox Form Element
// 7. handleOptionValues -> Represents the function to handle the Text Value of the option/s of the Checkbox Form Element
// 8. deleteOption -> Represents the function to handle deleting of an option of the Checkbox Form Element
// 9. duplicateElement -> Represents the function to handle duplicating the Form Element
const CheckboxInput = (props: any) => {
    const { addOption, item, handleValue, handleOptionValues, deleteOption, index } = props;
    // Function to generate a new ID for the option and set the value to an empty string to pass it onto the `addOption` function
    const createNewOption = (id: any) => {
        const data = {
            id: uuid(),
            value: "",
        };
        addOption({ id, newOption: data, index });
    };

    return (
        <SurveyElementWrapper {...props}>
            <Grid container display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Typography>Question</Typography>
                <TextField
                    defaultValue={item.question_text}
                    variant="outlined"
                    onBlur={(e) => handleValue({ id: item.id, e, index })}
                    fullWidth
                    required={!!item.is_required}
                    placeholder="Question"
                    sx={{ mb: 2 }}
                    InputProps={{
                        sx: {
                            borderRadius: "1rem",
                            padding: "6px 1rem",
                            fontSize: "14px",
                        }
                    }}
                    multiline
                />
            </Grid>
            {item.answer_options &&
                item.answer_options.length > 0 &&
                item.answer_options.map((opt: any, key: number) => (
                    <Box key={key} sx={{ display: "flex", alignItems: "center", gap: "1rem"  }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={`Checkbox Option ${key + 1}`}
                            defaultValue={opt?.value}
                            key={opt?.id + key + 1}
                            sx={{ mb: 1 }}
                            InputProps={{
                                sx: {
                                    borderRadius: "1rem",
                                    padding: "0px 1rem !important",
                                    fontSize: "14px",
                                }
                            }}
                            onBlur={(e) =>
                                handleOptionValues({
                                    elId: item?.id,
                                    optionId: opt?.id,
                                    optionVal: e.target.value,
                                    index,
                                })
                            }
                        />
                        <Typography 
                            aria-label="delete-option"
                            onClick={() => deleteOption({ elId: item.id, optionId: opt?.id, index })}
                            sx={{ ml: 2 }}
                        >
                            Remove
                        </Typography>
                        {/* <Tooltip title="Delete Option" aria-label="delete-option">
                            <IconButton
                                aria-label="delete-option"
                                onClick={() => deleteOption({ elId: item.id, optionId: opt?.id, index })}
                                sx={{ ml: 2 }}
                            >
                                <DeleteOutlineOutlinedIcon color="primary" />
                            </IconButton>
                        </Tooltip> */}
                    </Box>
                ))}
            <Button variant="text" onClick={() => createNewOption(item.id)}>
                Add Option
            </Button>
        </SurveyElementWrapper>
    );
};

export default CheckboxInput;
