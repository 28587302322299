// Material UI Components
import FormGroup from "@material-ui/core/FormGroup";

// Material Icons (Delete and Copy)
import { Typography } from '@mui/material';

// `FormElementFooter` is the function to show the user the Footer for each Form Element
// 1. item -> Represents the Form Item Object (Consisting of all properties)
// 2. deleteEl -> Represents the function to handle deleting the Form Element
// 3. duplicateElement -> Represents the function to handle duplicating the Form Element
// 4. handleRequired -> Represents the function to handle making a Form Element required/not required
const SectionFooter = ({ item, deleteSec, addElement, index }: any) => {
    return (
        <>
            {/* <Divider light /> */}
            <FormGroup row sx={{ alignItems: "center" }}>
                {/* <Tooltip title="Add Element" aria-label="duplicate-element"> */}
                    {/* <IconButton aria-label="add-element"  sx={{ ml: 2 }}>
                        <AddCircleOutlineOutlinedIcon color="primary" />
                    </IconButton> */}
                    <Typography onClick={() => addElement({ index })} style={{color: "#29ABE2", cursor: "pointer", margin: "10px 1rem"}}>Add unpacking question</Typography>
                {/* </Tooltip> */}
                {/* <Tooltip title="Delete Section" aria-label="delete-section">
                    <IconButton
                        aria-label="delete-section"
                        onClick={() => deleteSec({ index, section_title: item.section_title, section_id: item.id })}
                        sx={{ ml: 2 }}
                    >
                        <DeleteOutlineOutlinedIcon color="primary" />
                    </IconButton>
                </Tooltip> */}
                {/* <Tooltip title="Duplicate Section" aria-label="duplicate-section">
                    <IconButton
                        aria-label="duplicate-section"
                        onClick={() => duplicateElement(item.id, item.answer_type)}
                        sx={{ ml: 2 }}
                    >
                        <FileCopyIcon color="secondary" />
                    </IconButton>
                </Tooltip> */}
            </FormGroup>
        </>
    );
};

export default SectionFooter;
