import { useAuth0 } from "@auth0/auth0-react";
import { setCurrentUser } from "app/slices/UserSlice";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const AuthContext = createContext<any>(null);

// `AuthProvider` is used to handle the Authentication of the user
export const AuthProvider = ({ children }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user, isAuthenticated, getIdTokenClaims, error } = useAuth0();
    const [idToken, setIdToken] = useState("");
    dispatch(setCurrentUser(user ? user : null));

    useEffect(() => {
        const cookies = new Cookies();
        const getCurrentUserIdTokenClaims = async () => {
            try {
                const claims: any = await getIdTokenClaims();
                const token = claims.__raw;
                cookies.set("idToken", token, { expires: new Date(claims.exp * 1000) });
                setIdToken(token);
            } catch (error) {
                console.log(error);
            }
        };
        if (isAuthenticated && !idToken) {
            getCurrentUserIdTokenClaims();
        } else if (error) {
            console.log("AuthProvider", error);

            // alert("Access Denied: You do not have permission to access this application.");
            // navigate(`/`);
        }
    }, [getIdTokenClaims, isAuthenticated, user, idToken]);

    return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

// `useAuth` is the function that let's us get the current state of the authentication
export const useAuth = () => {
    return useContext(AuthContext);
};
