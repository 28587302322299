import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import ShieldIcon from "assets/icons/Shield.svg";
import React from "react";
import { User } from "types/user";
import Cookies from "universal-cookie";
import styles from "./styles";

type PropsType = {
    user: User | null;
};

const UserProfilePopover: React.FC<PropsType> = ({ user }) => {
    const { logout } = useAuth0();
    const cookies = new Cookies();

    const logoutWithRedirect = () => {
        cookies.remove("idToken");
        logout({ returnTo: window.location.origin });
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.profileContainer}>
                <img style={styles.profileImage} src={user?.img} alt="profileImg" />
                {/* <Box sx={styles.editImageContainer}>
                    <img src={editPhotoIcon} alt="Logo" style={styles.editImageIcon} />
                </Box> */}
            </Box>
            <Box sx={styles.flexContainer}>
                <Typography sx={styles.userName}>{user?.name}</Typography>
                <img src={ShieldIcon} alt="Switch Icon" style={styles.verifiedBadge} />
            </Box>
            <Typography sx={styles.subTitle}>{user?.email}</Typography>
            {/* <button style={styles.switchAccount}>
                <img style={styles.icon} src={SwitchAccountLogo} alt="Switch Icon" />
                <Typography sx={{ color: "#929292" }}>Switch account</Typography>
            </button>
            <Typography sx={styles.actionItem}>Edit Your Information</Typography> */}
            <Typography sx={styles.actionItem}>Dashboard</Typography>
            <Typography sx={styles.actionItem} onClick={() => logoutWithRedirect()}>
                Logout
            </Typography>
            <Typography sx={styles.subTitle}>Terms | Privacy</Typography>
        </Box>
    );
};

export default UserProfilePopover;
