import { Box, Checkbox, CssBaseline, FormControl, FormControlLabel, FormGroup, IconButton } from "@mui/material";
import EyeIcon from "assets/icons/eye.svg";
import TrashIcon from "assets/icons/trash-red.svg";
import Header from "components/Dashboard/Header";
import { useGetIsPublicStatus, useRevertIsPublic } from "hooks/CoPilots";
import { Button, Tabs } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "../../Survey/CreateSurvey/styles";
import AddOptions from "../AddOptions";

interface tab {
    name: string;
    url: string;
}

const tabs: tab[] = [
    {
        name: "Copilot",
        url: "/v2/admin/copilots/create",
    },
    {
        name: "Assign",
        url: "/v2/admin/copilots/create/assign",
    },
];

function CreateCoPilotLayout() {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    const id = searchParams.get("id");
    const isEdit = searchParams.get("isEdit");
    const childRef = useRef<any>();
    const navigate = useNavigate();
    const [noOfUsers, setNoOfUsers] = useState([0, 1, 2, 3, 4, 5, 6].map((value) => ({ value: value, label: value })));
    const [selectedNoOfUsers, setSelectedNoOfUsers] = useState(null);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [coPilotOptions, setCoPilotOptions] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<tab>({
        name: "",
        url: "",
    });

    const onTabSelect = (tab: tab) => {
        setActiveTab(tab);
        navigate(`${tab.url}?title=${title}&id=${id}&isEdit=${isEdit}`);
    };

    useEffect(() => {
        const tab: tab = tabs.find((tab) => location.pathname == tab.url) || {
            name: "",
            url: "",
        };
        setActiveTab(tab);
    }, []);

    useEffect(() => {
        if (optionsOpen) {
            refetchData();
        }
    }, [optionsOpen]);

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };

    const handleOptionsClick = () => {
        setOptionsOpen(true);
    };

    const onSuccessFetch = (data: any) => {
        setCoPilotOptions([
            {
                text: "Publish this CoPilot to the VSorts AI platform and allow the public to view and use it",
                checked: data?.is_public,
            },
        ]);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetIsPublicStatus({
        coPilotID: id,
        isEnabled: optionsOpen,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const onSaveSuccess = (data: any) => {
        setOptionsOpen(false);
    };
    const onSaveError = (error: any) => {
        setOptionsOpen(false);
    };

    const {
        mutate: callingCopilot,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useRevertIsPublic({
        coPilotID: id,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const handleSaveOptions = async () => {
        await callingCopilot();
    };

    const handleSectionClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.addSection === "function") {
                childRef.current.addSection();
            }
        }
    };

    const previewCoPilot = () => {
        navigate(`/v2/admin/copilots/preview?title=${title}&id=${id}`);
    };

    const handleOptionSelection = (event: any) => {
        const nextState = coPilotOptions.map((eachCoPilot: any) => {
            if (eachCoPilot?.text == event.target.value) {
                return {
                    ...eachCoPilot,
                    checked: !eachCoPilot?.checked,
                };
            } else {
                return eachCoPilot;
            }
        });
        setCoPilotOptions(nextState);
    };

    const checkDisabled = () => {
        let count = 0;
        for (let i = 0; i < coPilotOptions.length; i++) {
            if (coPilotOptions[i]["checked"]) {
                count = count + 1;
                break;
            }
        }
        return count;
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="Create a CoPilot"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container}>
                <IconButton sx={{ width: "42px", height: "42px" }} onClick={previewCoPilot}>
                    <img src={EyeIcon} alt="view" />
                </IconButton>

                <input className="titleInput" value={title ?? ""} disabled />
                <Button type="primaryOutlined" text="Options" onClick={() => handleOptionsClick()} />

                <Button type="primary" text="Save" onClick={() => handleSaveClick()} />

                <IconButton sx={{ width: "42px", height: "42px" }}>
                    <img src={TrashIcon} alt="Delete" />
                </IconButton>
            </Box>

            <div style={{ marginTop: "10px" }}>
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} onTabSelect={onTabSelect} />{" "}
            </div>
            {/* <Box sx={{ ...styles.container, alignItems: "center" }}>
                <FormControlLabel control={<Checkbox />} label="Allow users to complete form more than 1 time" />
                <Box sx={{ ...styles.selectStyles }}>
                    <ReactSelect
                        data={noOfUsers}
                        label={"Select 0-6 (Default is 1)"}
                        defaultValue={selectedNoOfUsers}
                        setValue={setSelectedNoOfUsers}
                        isDisabled={false}
                    />
                </Box>
            </Box> */}

            {optionsOpen && (
                <AddOptions
                    open={optionsOpen}
                    setOpen={setOptionsOpen}
                    title={"CoPilot options"}
                    subTitle={"Add more control over a CoPilot with the options listed below"}
                >
                    {coPilotOptions && (
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                {(coPilotOptions || []).map((eachOption: any, index: number) => {
                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={eachOption?.text}
                                            control={
                                                <Checkbox
                                                    checked={eachOption?.checked}
                                                    onChange={handleOptionSelection}
                                                    name={eachOption?.text}
                                                />
                                            }
                                            label={eachOption?.text}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </FormControl>
                    )}
                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                        <Button
                            // disabled={checkDisabled() == 0 ? true : false}
                            type="primary"
                            text="Save"
                            onClick={() => handleSaveOptions()}
                        />
                    </Box>
                </AddOptions>
            )}
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                <Outlet context={{ childRef }} />
            </Box>
        </Box>
    );
}

export default CreateCoPilotLayout;
