import { Box, CssBaseline, Typography } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import Header from "components/Dashboard/Header";
import { Button } from "pages/adminV2/Components";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
    useVSetClusterAnswers,
    useVSetClusterInformation,
    useVSetClusters,
} from "../../../../hooks/VSortsContainerData";
import ReactSelect from "../../Components/Select";
import "./VSet/VSetUserCard/VSetUserCard.css";
import "./ViewVSets.css";
import styles from "./styles";

interface Domain {
    domain_name: string;
    header_text: string;
}

interface ClusterAnswerResponses {
    id: number;
    answer_id: number;
    answer_type: string;
    user_id: string;
    answer: string;
    question_text: string;
    question_id: number;
    question_number: number;
    vignette_id: number;
}

interface ClusterInformation {
    domains: Domain[];
}

interface Value {
    value: number;
    label: string;
}

interface Options {
    value: number;
    label: string;
}

function ViewVSetsLayout() {
    const childRef = useRef<any>();
    const org_id = "org_NYZu26HrSYyyrbD6";
    const form_type = "vsorts";
    const [userResponses, setUserResponses] = useState({});
    const [clusterInformation, setClusterInformation] = useState<ClusterInformation | null>(null);
    const [clusterAnswers, setClusterAnswers] = useState<ClusterAnswerResponses[]>([]);
    const [options, setOptions] = useState<Options[]>([]);
    const [value, setValue] = useState<Value>({ value: 0, label: "" });
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const container_id = params.get("container_id");
    const [cluster_id, setCluster_id] = useState<number>(value.value);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (value && value.value) {
            setCluster_id(value.value);
        }
    }, [value]);

    useEffect(() => {
        setLoading(true);
    }, [value]);

    const {
        data: clustersData,
        error: clustersError,
        isLoading: clustersLoading,
        isError: clustersIsError,
    } = useVSetClusters({
        org_id,
        form_type,
        container_id,
        onSuccess: (data: any) => {
            setUserResponses(data);
            setOptions(
                data.map((cluster: any) => ({
                    value: cluster.cluster_id,
                    label: cluster.cluster_name,
                }))
            );

            if (cluster_id === 0 || cluster_id === undefined) {
                setCluster_id(data[0].cluster_id);
                setValue({ value: data[0].cluster_id, label: data[0].cluster_name });
            }
        },
        onError: (error: any) => {
            console.error("Error fetching data:", error);
        },
        isEnabled: true,
    });

    const {
        data: clusterInfoData,
        error: clusterInfoError,
        isLoading: clusterInfoLoading,
        isError: clusterInfoIsError,
    } = useVSetClusterInformation({
        container_id,
        cluster_id,
        value,
        onSuccess: (data: any) => {
            setClusterInformation(data);
            setLoading(false);
        },
        onError: (error: any) => {
            console.error("Error fetching cluster information:", error);
        },
        isEnabled: cluster_id !== 0 && cluster_id !== undefined,
    });

    const {
        data: clusterAnswersData,
        error: clusterAnswersError,
        isLoading: clusterAnswersLoading,
        isError: clusterAnswersIsError,
    } = useVSetClusterAnswers({
        container_id,
        cluster_id,
        clusterInformation,
        onSuccess: (data: any) => {
            setClusterAnswers(data);
        },
        onError: (error: any) => {
            console.error("Error fetching cluster information:", error);
        },
        isEnabled: cluster_id !== 0 && cluster_id !== undefined,
    });

    const handleSaveClick = () => {
        if (childRef.current) {
            if (typeof childRef.current.save === "function") {
                childRef.current.save();
            }
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="VSet Data"
                onIconClick={() => {
                    undefined;
                }}
            />

            <Box sx={styles.container} display="flex" alignItems="center">
                <Typography>
                    <strong>BIPOC</strong>: {clusterInformation?.domains[0]?.domain_name};{" "}
                    {clusterInformation?.domains[0]?.header_text}
                </Typography>

                <Box display="flex" alignItems="center" gap="2rem">
                    <ReactSelect
                        label={"Select cluster"}
                        data={options}
                        isFixedWidth={true}
                        setValue={setValue}
                        defaultValue={value}
                    />
                    <Button type="primary" text="Exit" onClick={() => handleSaveClick()} />
                </Box>
            </Box>

            {loading ? (
                <CircularLoadingProgress />
            ) : (
                <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                    <Outlet
                        context={{ childRef, clusterInformation, clusterAnswers, container_id, cluster_id, loading }}
                    />
                </Box>
            )}
        </Box>
    );
}

export default ViewVSetsLayout;
