const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        gap: ".1rem",
        justifyContent: "space-between",
        alighItems: "center",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
    },
    buttonContainer: {
        flex: "1",
        display: "flex",
        gap: "10px",
    },
    searchContainer: {
        flex: "2",
        width: "100%",
    },
};

export default styles;
