import MailIcom from "assets/icons/emailblue.svg";
import FacebookIcon from "assets/icons/facebookblue.svg";
import LinkedinIcon from "assets/icons/linkedinblue.svg";
import PhoneIcom from "assets/icons/phoneblue.svg";
import TwitterIcon from "assets/icons/twitterblue.svg";
import YoutubeIcon from "assets/icons/youtubeblue.svg";
import Footer from "components/Footer";
import { SECTIONS } from "constants/landing-page.constants";
import { RefObject } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

interface Props {
    onScroll: (refName: string) => void;
    contactRef: RefObject<HTMLDivElement>;
}

const LandingPageFooter: React.FC<Props> = ({ onScroll, contactRef }) => {
    return (
        <div className="footerContainer" ref={contactRef}>
            <div className="footerLinksContainer">
                <ul>
                    <li className="footerLinkTitle footerBlue">Quick Links</li>
                    <li>
                        <a onClick={() => onScroll(SECTIONS.VSORTS)}>What is VSorts?</a>
                    </li>
                    <li>
                        <a onClick={() => onScroll(SECTIONS.FEATURES)}>Features</a>
                    </li>
                    <li>
                        <a onClick={() => onScroll(SECTIONS.ENTERPRISE)}>Enterprise</a>
                    </li>
                    <li>
                        <a onClick={() => onScroll(SECTIONS.CONTACT)}>Contact</a>
                    </li>
                </ul>

                <ul>
                    <li className="footerLinkTitle footerBlue">Contact us</li>
                    <li>
                        <img src={PhoneIcom} alt="Arrow" /> &nbsp;&nbsp;610-808-7067
                    </li>
                    <li>
                        <img src={MailIcom} alt="Arrow" /> &nbsp;&nbsp;help@vsorts.com
                    </li>
                </ul>
            </div>
            <div className="footerSocialsContainer">
                <Link to={"https://www.facebook.com/vsorts"} target="_blank">
                    <img src={FacebookIcon} alt="Arrow" />
                </Link>
                <Link to={"https://twitter.com/VSortsAI"} target="_blank">
                    <img src={TwitterIcon} alt="Arrow" />
                </Link>
                <Link to={"https://www.linkedin.com/company/vsortsai/"} target="_blank">
                    <img src={LinkedinIcon} alt="Arrow" />
                </Link>
                <Link to={"https://www.youtube.com/@VSorts"} target="_blank">
                    <img src={YoutubeIcon} alt="Arrow" />
                </Link>
            </div>
            <Footer />
        </div>
    );
};

export default LandingPageFooter;
