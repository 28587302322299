// Form Elements

// Material Imports

import { Grid, Typography } from "@mui/material";
// import movingDots from "../../../assets/images/movingDots.png";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import styles from "./styles";
export default function VignetteTextDisplay({ vignette_text, unpacked, customStyles, showDragIcon=true }: any) {
    return (
        <Grid
            container
            spacing={1}
            mt={0}
            sx={[styles.vignetteTextDisplayGrid, customStyles]}
            style={{
                backgroundColor: unpacked ? "#009245" : "#29ABE2",
                cursor: unpacked ? "" : "pointer",
            }}
        >
            <Grid item sx={styles.bluePortion}>
                <Grid container>
                    <Grid mb={1} item xs={11} sx={styles.textGrid} py={1}>
                        <Typography sx={styles.vignetteText}>{vignette_text}</Typography>
                    </Grid>
                    {showDragIcon && (
                        <Grid item xs={1} mb={1} pr={1} sx={styles.dragIconGrid}>
                            <DragIndicatorOutlinedIcon sx={{ color: "#C0C0C0" }} />
                        </Grid>
                    )}
                    
                </Grid>
            </Grid>
        </Grid>
    );
}
