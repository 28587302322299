// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Material Imports
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TimePicker from "@material-ui/lab/TimePicker";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VSHelperText from "components/VSHelperText";

import VSBox from "components/VSBox";
import VSTypography from "components/VSTypography";

// Constants
import { CustomConstants } from "constants/custom.constants";

const ViewElementFormLabel = ({ hasError, label, isRequired, id, type = "form" }) => {
    const Component = type === "form" ? FormLabel : InputLabel;
    return (
        <Component id={id} error={hasError} style={{ color: "#111" }}>
            {label}
            {isRequired ? "*" : ""}
        </Component>
    );
};

const ViewElementHelperText = ({ hasError, message }) => {
    if (!hasError) {
        return null;
    }
    return (
        <VSHelperText error variant="standard">
            {message}
        </VSHelperText>
    );
};

// `ViewElements` is the function to show the user the Form Elements in Viewer Mode
// 2. item -> Represents the Form Item Object (Consisting of all properties)
export const ViewElements = ({ item, handleValue, isRequired, hasError }) => {
    const errorMsg = "Required";

    switch (item.answer_type) {
        case "text":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <TextField
                        fullWidth
                        label={item.question_text}
                        onChange={(event) => handleValue(event, item)}
                        value={item.answer}
                        required={isRequired}
                        error={!!hasError}
                        helperText={hasError && errorMsg}
                        FormHelperTextProps={{ variant: "standard" }}
                        multiline
                    />
                </Box>
            );
        case "textarea":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <TextField
                        fullWidth
                        label={item.question_text}
                        multiline
                        rows={3}
                        value={item.answer}
                        onChange={(event) => handleValue(event, item)}
                        required={isRequired}
                        error={!!hasError}
                        helperText={hasError && errorMsg}
                        FormHelperTextProps={{ variant: "standard" }}
                    />
                </Box>
            );
        case "header_info":
            return (
                <VSBox sx={{ pt: 3 }} key={item.id}>
                    <VSTypography sx={{ color: "#111111" }}>{item.question_text}</VSTypography>
                </VSBox>
            );
        case "number":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <TextField
                        fullWidth
                        label={item.question_text}
                        type="number"
                        value={item.answer}
                        onChange={(event) => handleValue(event, item)}
                        required={isRequired}
                        error={!!hasError}
                        helperText={hasError && errorMsg}
                        FormHelperTextProps={{ variant: "standard" }}
                    />
                </Box>
            );
        case "radio":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl fullWidth>
                        <ViewElementFormLabel
                            style={{ color: "#111111" }}
                            id="demo-radio-buttons-group-label"
                            hasError={hasError}
                            label={item.question_text}
                            isRequired={isRequired}
                        />
                        <RadioGroup
                            sx={{ mt: 30 }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="radio-buttons-group"
                            onChange={(event) => handleValue(event, item)}
                        >
                            {item.answer_options.map((individualItem, index) => {
                                return (
                                    <FormControlLabel
                                        sx={{
                                            border: "1px solid #F3F8FB",
                                            borderRadius: "25px",
                                            marginBottom: "20px",
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            width: "100%",
                                        }}
                                        key={index + individualItem.id}
                                        value={individualItem.value}
                                        control={<Radio />}
                                        label={individualItem.value}
                                        checked={individualItem.value === item.answer}
                                    />
                                );
                            })}
                        </RadioGroup>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "checkbox":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl>
                        <ViewElementFormLabel
                            id="demo-radio-checkbox-buttons-group-label"
                            hasError={hasError}
                            label={item.question_text}
                            isRequired={isRequired}
                        />
                        <RadioGroup
                            aria-labelledby="demo-radio-checkbox-buttons-group-label"
                            defaultValue={null}
                            name="radio-buttons-group"
                        >
                            {item.answer_options.map((individualItem, index) => {
                                return (
                                    <FormControlLabel
                                        key={index + individualItem.id}
                                        value={individualItem.value}
                                        control={<Checkbox onChange={(event) => handleValue(event, item)} />}
                                        label={individualItem.value}
                                        checked={(item.answer || []).includes(individualItem.value)}
                                    />
                                );
                            })}
                        </RadioGroup>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "dropdown":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl fullWidth error={hasError}>
                        <InputLabel id="demo-simple-select-label">
                            {item.question_text} {isRequired ? "*" : ""}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.answer}
                            defaultValue=""
                            label={item.question_text}
                            onChange={(event) => handleValue(event, item)}
                        >
                            {item.answer_options.map((individualItem, index) => {
                                return (
                                    <MenuItem key={index + individualItem.id} value={individualItem.value}>
                                        {individualItem.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "date":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={`${item.question_text},${item.answer || ""}`}
                            value={item?.answer || null}
                            onChange={(newDate) => handleValue(newDate, item)}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    {...params}
                                    required={isRequired}
                                    error={!!hasError}
                                    helperText={hasError && errorMsg}
                                    FormHelperTextProps={{ variant: "standard" }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "time":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            label={item.question_text}
                            value={item?.answer || null}
                            onChange={(newTime) => handleValue(newTime, item)}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    {...params}
                                    required={isRequired}
                                    error={!!hasError}
                                    helperText={hasError && errorMsg}
                                    FormHelperTextProps={{ variant: "standard" }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
            );
        case "state":
            return (
                <Box sx={{ pt: 3 }} key={item.id}>
                    <FormControl fullWidth error={hasError}>
                        <InputLabel id="demo-simple-state-select-label">
                            {item.question_text} {isRequired ? "*" : ""}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-state-select-label"
                            id="demo-simple-state-select"
                            value={item.answer}
                            label={item.question_text}
                            onChange={(event) => handleValue(event, item)}
                            defaultValue=""
                        >
                            {CustomConstants.usaStates.map((individualItem, index) => {
                                return (
                                    <MenuItem key={index + individualItem.value} value={individualItem.value}>
                                        {individualItem.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <ViewElementHelperText hasError={hasError} message={errorMsg} />
                    </FormControl>
                </Box>
            );
        case "section":
            return (
                <Box sx={{ pt: 3, pb: 3 }} key={item.id}>
                    <Typography variant="h5" component="div" gutterBottom>
                        {item.question_text}
                    </Typography>
                    <hr size="5" />
                </Box>
            );
        default:
            return <></>;
    }
};
