import { Box, CssBaseline, Typography } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import Header from "components/Dashboard/Header";
import { useGetSpecificCoPilot } from "hooks/CoPilots";
import AdminHeader from "pages/adminV2/Components/AdminHeader";
import Button from "pages/adminV2/Components/Button";
import NetworkError from "pages/error/networkError";
import { useEffect, useState } from "react";
import styles from "../../Survey/CreateSurvey/styles";
import ViewCoPilotResponses from "./ViewCoPilotResponses";

export default function ViewCoPilotResponsesLayout() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    const [coPilotData, setCoPilotData] = useState({
        title: "",
        subTitle: "",
        directions: "",
        promptMessage: "",
        selectedEngine: "",
    });

    useEffect(() => {
        refetchData();
    }, []);

    const handleExitPreview = () => {
        window.history.back();
    };

    const onSuccessFetch = (data: any) => {
        setCoPilotData({
            ...coPilotData,
            title: data?.title,
            subTitle: data?.sub_title,
            directions: data?.directions,
            promptMessage: data?.prompt_message,
            selectedEngine: data?.engine_id,
        });
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetSpecificCoPilot({
        coPilotID: id,
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    if (isDataFetching || isDataLoading) {
        return <CircularLoadingProgress />;
    }

    if (isDataError) {
        return <NetworkError handleOnRefresh={refetchData} errorText={error} />;
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "0rem 3rem" }}>
            <CssBaseline />
            <Header />
            <AdminHeader
                title="CoPilot data"
                onIconClick={() => {
                    undefined;
                }}
            />
            <Box sx={styles.container}>
                <Typography>
                    <span style={{ fontWeight: "bold" }}>CoPilot: </span>
                    {coPilotData?.title ?? ""}
                </Typography>

                <Button type="primary" text="Exit" onClick={() => handleExitPreview()} />
            </Box>

            <Box
                style={{
                    border: "1px solid #29ABE2",
                    width: "100%",
                    padding: "20px 34px",
                    background: "#F3F8FB",
                    borderRadius: 10,
                    // boxShadow: "0px 10px 5px 0.8px rgba(41, 171, 226, 0.08)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 12,
                    borderColor: "#29ABE2",
                }}
            >
                <Typography>
                    <span style={{ fontWeight: "bold" }}>CoPilot Prompt: </span>
                    {coPilotData?.promptMessage ?? ""}
                </Typography>
            </Box>
            <ViewCoPilotResponses coPilotId={id} />
        </Box>
    );
}
