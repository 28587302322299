import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography, styled } from "@mui/material";
import CircularLoadingProgress from "components/CircularProgress";
import VSButton from "components/VSButton";
import { useCallAICopilot, useGetPreviousEntries, useGetSpecificCoPilot } from "hooks/CoPilots";
import { ColorButton } from "pages/user-portal/vSets/SortingItems";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userPortalStyles from "../../user-portal/styles";
import ViewPreviousEntries from "./ViewPreviousEntries";
import CoPilotModal from "./coPilotModal";
import coPilotStyle from "./coPilotPageStyles";

const WhiteBorderTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        background: #f5f8fb;
        border-radius: 10px;

        &.Mui-focused fieldset {
            border-color: #5f7c88;
        }
        &:hover fieldset {
            border-color: #5f7c88;
        }
        & fieldset {
            border-color: #5f7c88;
        }
    }
    &::placeholder {
        color: red;
    }
    & .MuiInputBase-input {
        background: #f5f8fb;
        &::placeholder {
            color: #325a6b;
            // padding-top: 10px;
            padding-left: 10px;
        }
    }
    & .MuiTextField-root {
        background: #f5f8fb;
        & fieldset {
            border-color: #5f7c88;
        }
    }
`;
export default function CoPilotPage({ preview = false, id }: any) {
    const { coPilotID } = useParams();
    const navigate = useNavigate();
    const [coPilotDetail, setCoPilotDetail] = useState<any>({});
    const [inputVignette, setInputVignette] = useState<any>("");
    const [userInput, setUserInput] = useState<any>("");
    const [showData, setShowData] = useState(false);
    const [outputVignetteData, setOutputVignetteData] = useState("");
    const [vClass, setVClass] = useState("");
    const [responseTime, setResponseTime] = useState<any>("");
    const [viewPrevious, setViewPrevious] = useState(false);
    const [previousEntriesData, setPreviousEntriesData] = useState<any>({});
    const [openCoPilotModal, setOpenCoPolitModal] = useState<boolean>(false);

    useEffect(() => {
        refetchData();
    }, []);
    const onSuccessFetch = (data: any) => {
        setCoPilotDetail(data);
    };

    const onPreviousEntriesSucessFetch = (data: any) => {
        setPreviousEntriesData(data);
    };

    const onErrorFetch = (error: any) => {
        undefined;
    };
    const {
        isFetched,
        isFetching: isDataFetching,
        isLoading: isDataLoading,
        isError: isDataError,
        data: allData,
        error,
        refetch: refetchData,
    } = useGetSpecificCoPilot({
        coPilotID: preview ? id : coPilotID,
        isEnabled: true,
        onSuccess: onSuccessFetch,
        onError: onErrorFetch,
    });

    const {
        isFetching: isPreviousEntriesFetching,
        isLoading: isPreviousEntriesLoading,
        isError: isPreviousEntriesError,
        data: previousData,
        refetch: refetchPreviousEntries,
    } = useGetPreviousEntries({
        coPilotID: coPilotID,
        isEnabled: !preview,
        onSuccess: onPreviousEntriesSucessFetch,
        onError: onErrorFetch,
    });

    const handleSubmit = async () => {
        if (inputVignette.length > 0 && !preview) {
            await callAICoPilot();
        }
    };

    const handleModalOpen = () => {
        setOpenCoPolitModal(true);
    };

    const onSaveSuccess = (data: any) => {
        setUserInput(inputVignette);
        setShowData(true);
        setOutputVignetteData(data.data.data?.explanation);
        setVClass(data.data.data?.v_class);
        setResponseTime(2.1);
        refetchPreviousEntries();
    };

    const viewOldQuery = (selectedQuery: any) => {
        setUserInput(selectedQuery?.input_vignette);
        setShowData(true);
        setOutputVignetteData(JSON.parse(selectedQuery?.copilot_response)?.explanation);
        setVClass(JSON.parse(selectedQuery?.copilot_response)?.v_class);
        setResponseTime(2.1);
        // refetchPreviousEntries();
    };

    const onSaveError = (data: any) => {
        undefined;
    };

    const {
        mutate: callingCopilot,
        isError: isPostError,
        reset: resetCallAICoPilot,
        isLoading: isPostLoading,
        error: postError,
    } = useCallAICopilot({
        data: {
            data: {
                vignette: inputVignette,
            },
        },
        coPilotID: coPilotID,
        onSuccess: onSaveSuccess,
        onError: onSaveError,
    });

    const callAICoPilot = async () => callingCopilot();

    const handleExit = () => {
        if (!preview) {
            navigate("/dashboard");
        }
    };

    const clearItems = () => {
        setShowData(false);
        setInputVignette("");
        setOutputVignetteData("");
        setVClass("");
        setResponseTime(0);
    };

    return (
        <>
            <Box sx={{ width: "70%", margin: "auto" }}>
                <Box>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography style={coPilotStyle.title}>{coPilotDetail?.title}</Typography>
                    </Grid>
                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" container>
                        <Typography style={coPilotStyle.directions}>
                            <b>Directions: </b> {coPilotDetail?.directions}
                            <span
                                onClick={() => setOpenCoPolitModal(true)}
                                style={{
                                    cursor: "pointer",
                                    color: "#009245",
                                }}
                            >
                                {" "}
                                How does this activity work?
                            </span>
                        </Typography>
                    </Grid>
                </Box>
                <Box>
                    {viewPrevious && (
                        <ViewPreviousEntries
                            previousEntriesData={previousEntriesData}
                            viewPrevious={viewPrevious}
                            viewOldQuery={viewOldQuery}
                        />
                    )}
                    <Paper
                        sx={{
                            p: "20px 40px 20px 40px",
                            boxShadow: "none",
                            borderRadius: "50",
                        }}
                    >
                        <Typography style={coPilotStyle.promptMessage}>{coPilotDetail?.prompt_message}</Typography>
                        <div style={coPilotStyle.textSection}>
                            <div style={{ height: "70vh", padding: "15px", overflowY: "scroll" }}>
                                {isPostLoading && (
                                    <div style={{ position: "relative", top: "50%" }}>
                                        <CircularLoadingProgress />
                                    </div>
                                )}
                                {showData && (
                                    <>
                                        <Typography style={coPilotStyle.outputTitles}>You</Typography>
                                        <Typography style={coPilotStyle.vignetteDataDisplay}>{userInput}</Typography>
                                        <Typography
                                            sx={coPilotStyle.outputTitles}
                                            style={{ color: "#29ABE2", paddingTop: "20px" }}
                                        >
                                            @VSorts AI
                                        </Typography>
                                        <Typography style={coPilotStyle.vignetteDataDisplay}>
                                            {outputVignetteData}
                                        </Typography>
                                        <Typography style={coPilotStyle.vignetteDataDisplay}>{vClass}</Typography>
                                    </>
                                )}
                            </div>
                            <Box sx={{ pt: 3 }} key={2} style={{ padding: "15px" }}>
                                <WhiteBorderTextField
                                    fullWidth
                                    label=""
                                    placeholder={showData ? "Clear to start a new response" : "Type here"}
                                    onChange={(event) => {
                                        if (!preview) setInputVignette(event.target.value);
                                    }}
                                    value={inputVignette}
                                    required={true}
                                    FormHelperTextProps={{ variant: "standard" }}
                                    multiline={true}
                                    aria-readonly={preview ? true : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    sx={coPilotStyle.submitButton}
                                                    onClick={handleSubmit}
                                                    style={{
                                                        background: inputVignette.length > 0 ? "#29ABE2" : "#C0C0C0",
                                                    }}
                                                >
                                                    <ArrowUpwardIcon style={{ color: "white" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                ></WhiteBorderTextField>
                            </Box>
                        </div>
                        <Box style={{ display: "flex", flexDirection: "row", marginTop: "2vh" }}>
                            {showData && (
                                <div
                                    style={{
                                        marginRight: "20px",
                                        marginTop: "1vh",
                                    }}
                                >
                                    <RefreshIcon
                                        style={{ color: "29ABE2", cursor: "pointer", marginRight: "10px" }}
                                        onClick={clearItems}
                                    />
                                    <Typography display={"inline"} style={coPilotStyle.vignetteDataDisplay}>
                                        Processed in: {responseTime} secs
                                    </Typography>
                                </div>
                            )}

                            <div style={{ marginLeft: "auto" }}>
                                <>
                                    <VSButton
                                        variant="contained"
                                        sx={{ ...userPortalStyles.btnExit, boxShadow: "none" }}
                                        onClick={handleExit}
                                    >
                                        Exit
                                    </VSButton>
                                    <ColorButton
                                        onClick={() => {
                                            if (!preview) setViewPrevious(!viewPrevious);
                                        }}
                                        variant="contained"
                                        sx={{ ...userPortalStyles.btnContinue, boxShadow: "none" }}
                                    >
                                        {viewPrevious ? "Hide previous entries" : "View previous entries"}
                                    </ColorButton>
                                </>
                            </div>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            {openCoPilotModal && <CoPilotModal open={openCoPilotModal} setOpen={setOpenCoPolitModal} />}
        </>
    );
}
