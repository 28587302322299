import AuthorDostIcon from "assets/icons/authordots.svg";
import CeoAvatar from "assets/icons/ceoavatar.svg";
import "./Mission.css";

const Mission: React.FC = () => {
    return (
        <div>
            <div className="missionContainer">
                <div className="missionImageContainer">
                    <img src={CeoAvatar} alt="testimonial" className="missionImage" />
                </div>
                <div className="missionContentContainer">
                    <div>
                        <h1 className="missionTitle">Our Vision.</h1>
                    </div>
                    <div>
                        Our vision for VSorts™ is to provide cutting-edge software and services for AI-assisted data collection, transcription, analysis, reporting, and storage, focusing on qualitative data through a unified platform in the advanced analytics and data management SaaS AI vertical markets.
                    </div>
                    <div className="missionAuthor">
                        <img src={AuthorDostIcon} alt="testimonial" /> Dwayne Ray
                        Cormier, Ph.D. <span className="fontItalic">Founder and CEO</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
