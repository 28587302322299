import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

// const fetchAllVSortsContainerCategories = () => {
//     return request({ url: NetworkConstants.getVSetsCategories });
// };

const addEditVSortsContainerData = ({ data, apiMethodType }: any) => {
    return request({ url: NetworkConstants.addVSortsContainerData, method: apiMethodType, data: data });
};

const fetchVSortsContainerData = ({ containerID }: any) => {
    return request({ url: NetworkConstants.getVSortsContainerData + containerID });
};

export const deleteVignettes = (data: any) => {
    return request({ url: NetworkConstants.deleteVignettes, method: APIMethodConstants.post, data });
};

const fetchVSetsContainerCategory = (containerID: any) =>
    request({ url: NetworkConstants.getVSetsContainerCategories + containerID, method: APIMethodConstants.get });

const fetchVSetsContainerParameters = (containerID: any) =>
    request({ url: NetworkConstants.getVSetsContainerParameters + containerID, method: APIMethodConstants.get });

const deleteVSetsParamRelation = ({ id }: any) => {
    return request({ url: NetworkConstants.getVSetsContainerParameters + id, method: APIMethodConstants.delete });
};

const fetchVSetsAllParameters = () =>
    request({ url: NetworkConstants.getVSetsContainerParameters, method: APIMethodConstants.get });

const createEditParamValues = ({ apiMethodType, data }: any) => {
    return request({ url: NetworkConstants.addVSetsContainerParameters, method: apiMethodType, data: data });
};

// useVSortsContainerCategoryData -> To fetch All VSorts Container Categories
export const useVSortsContainerCategoryData = ({ containerID, onSuccess, onError }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetsContainerCategories, containerID],
        () => fetchVSetsContainerCategory(containerID),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                // Adding a new item with value Domain for key category_name and returning the data
                // This is done to ensure that the UI sees the appropriate response to create the table with domain column along
                // with the other categories
                return data.data.data;
            },
        }
    );
};

// useVSortsContainerData -> To fetch All Information for a VSorts Container
export const useVSortsContainerData = ({ containerID, onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSortsContainerDataQueryKey, containerID],
        () => fetchVSortsContainerData({ containerID: containerID }),
        {
            enabled: isEnabled,
            staleTime: 300000,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useAddEditVSortsContainerData -> To add/edit data to the VSorts Container
export const useAddEditVSortsContainerData = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        (variables: any) =>
            addEditVSortsContainerData({ data: variables.data, apiMethodType: variables.apiMethodType }),
        {
            onSuccess: (_data, variables) => {
                const containerID = variables.data.container_id;
                navigate(-1);
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsContainerDataQueryKey, containerID]);
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSetsContainerCategories, containerID]);
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsContainerQueryKey);
            },
        }
    );
};

// Same as useAddEditVSortsContainerData without navigation
export const useAddEditVSortsContainer = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (variables: any) =>
            addEditVSortsContainerData({ data: variables.data, apiMethodType: variables.apiMethodType }),
        {
            onSuccess: (_data, variables) => {
                const containerID = variables.data.container_id;
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsContainerDataQueryKey, containerID]);
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSetsContainerCategories, containerID]);
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsContainerQueryKey);
            },
        }
    );
};

// useVSortsContainerParameterData -> To fetch All VSorts Container Parameters
export const useVSortsContainerParameterData = ({ onSuccess, onError, containerID }: any) => {
    return useQuery(
        [QueryKeyConstants.fetchVSetsContainerParameters, containerID],
        () => fetchVSetsContainerParameters(containerID),
        {
            staleTime: Infinity,
            onSuccess,
            onError,
            select: (data) => {
                return data.data.data;
            },
        }
    );
};

// useVSortsAllParameters -> To fetch All VSorts Container Parameters
export const useVSortsAllParameters = ({ onSuccess, onError }: any) => {
    return useQuery([QueryKeyConstants.fetchVSetsAllParameters], () => fetchVSetsAllParameters(), {
        staleTime: Infinity,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

// useDeleteVSortParameterRelation -> To delete a parameter from a VSorts Cluster
export const useDeleteVSortParameterRelation = ({ id }: any) => {
    return useMutation((variables: any) => deleteVSetsParamRelation({ id: variables.id }));
};

// useCreateEditParamValues -> To add parameters to VSorts Cluster
export const useCreateEditParamValues = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        ({ data, apiMethodType }: any) => createEditParamValues({ data: data, apiMethodType: apiMethodType }),
        {
            // We navigate back to the previous page if the Network Call is successful
            onSuccess: (_res, variables) => {
                const { cluster_id: clusterID } = variables.data;
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsClusterSteps, clusterID]);
                queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey);
                navigate(-1);
            },
        }
    );
};

// useCreateEditParamValues -> To add parameters to VSorts Cluster
export const useCreateEditVsetParameters = (containerId: any) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        ({ data, apiMethodType }: any) => createEditParamValues({ data: data, apiMethodType: apiMethodType }),
        {
            // We navigate back to the previous page if the Network Call is successful
            onSuccess: (_res, variables) => {
                const { cluster_id: clusterID } = variables.data;

                // queryClient.invalidateQueries([QueryKeyConstants.fetchVSortsClusterSteps, clusterID]);
                // queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey)
                queryClient.invalidateQueries([QueryKeyConstants.fetchVSetsContainerParameters, containerId]);
            },
        }
    );
};
