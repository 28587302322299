import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { MinimumUnpackingCountSelector, UnpackedVignetteCountSelector } from "app/slices/UnpackingSlice";
import VSBorderLinearProgress from "components/VSBorderLinearProgress";
import VSCard from "components/VSCard/VSCard";
import { useCompleteUnpackingStep } from "hooks/VSortsContainerData";
import { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AllCategoryVignettes from "./allCategoryVignettes";
import SelectedCategoryVignettes from "./selectedCategoryVignettes";
import styles from "./styles";

export default function ParkingLotVignettes({
    data,
    setVignetteSelected,
    openUnpackingDialog,
    availableVignettes = 0,
    cluster_step_id
}: any) {
    const [selectedDomain, setSelectedDomain] = useState("All");
    const [flaggedVignettes, setFlaggedVignettes] = useState([]);

    const [vignetteCount, setVignetteCount] = useState({
        flaggedVignettes: 0,
        totalVignettes: 0,
    });

    const navigate = useNavigate();
    const unpackedVignetteCount = useSelector(UnpackedVignetteCountSelector);
    const minimumUnpackingCount = useSelector(MinimumUnpackingCountSelector);

    const {
        mutate: completeUnpackingStepMutate,
        isError: isCompleteUnpackingError,
        reset: resetcompleteUnpackingStep,
    } = useCompleteUnpackingStep({
        data: {
            cluster_step_id: cluster_step_id
        },
    });

    const completeUnpackingStep = async () => completeUnpackingStepMutate();

    useEffect(() => {
        setFlaggedVignettes(data);
        setVignetteSelected(data[0].vignettes[0]);
        let flaggedVignettes = 0;
        let totalVignettes = 0;

        data?.map((eachDomain: { totalVignettesCount: number; flaggedVignettesCount: number }) => {
            totalVignettes = totalVignettes + eachDomain.totalVignettesCount;
            flaggedVignettes = flaggedVignettes + eachDomain.flaggedVignettesCount;
        });
        const testVignetteCount = {
            flaggedVignettes: flaggedVignettes,
            totalVignettes: totalVignettes,
        };
        setVignetteCount(testVignetteCount);
    }, []);

    const chooseSelectedDomain = (domain: SetStateAction<string>) => {
        setSelectedDomain(domain);
    };

    const isUnpackingComplete = () => {
        if (availableVignettes == 0) {
            return true;
        }
        if (availableVignettes == unpackedVignetteCount) {
            return true;
        }
        if (unpackedVignetteCount >= minimumUnpackingCount) {
            return true;
        } else {
            return false;
        }
    };


    const finishUnpacking = async () => {
        await completeUnpackingStep();
        location.reload();
    }

    return (
        <VSCard sx={styles.outerCard}>
            <Grid container spacing={1} sx={{ width: "100%" }}>
                <Grid item xs={2}>
                    <Typography sx={styles.categoryTitle}>Categories</Typography>
                    <Typography
                        sx={styles.categoryList}
                        style={{
                            fontWeight: selectedDomain == "All" ? "800" : "400",
                            color: selectedDomain == "All" ? "#29ABE2" : "",
                            fontStyle: selectedDomain == "All" ? "bold" : "",
                        }}
                        onClick={() => chooseSelectedDomain("All")}
                    >
                        All ({vignetteCount["flaggedVignettes"]}/{vignetteCount["totalVignettes"]})
                    </Typography>

                    {flaggedVignettes?.map((eachDomain) => {
                        return (
                            <Typography
                                sx={styles.categoryList}
                                style={{
                                    fontWeight: selectedDomain == eachDomain["domain_name"] ? "800" : "400",
                                    color: selectedDomain == eachDomain["domain_name"] ? "#29ABE2" : "",
                                    fontStyle: selectedDomain == eachDomain["domain_name"] ? "bold" : "",
                                }}
                                onClick={() => chooseSelectedDomain(eachDomain["domain_name"])}
                            >
                                {eachDomain["domain_name"]} ({eachDomain["flaggedVignettesCount"]}/
                                {eachDomain["totalVignettesCount"]})
                            </Typography>
                        );
                    })}

                    <Box style={{ marginTop: "67vh" }}>
                        <VSBorderLinearProgress
                            sx={styles.linearProgress}
                            variant="determinate"
                            value={
                                (unpackedVignetteCount / minimumUnpackingCount) * 100 <= 100
                                    ? (unpackedVignetteCount / minimumUnpackingCount) * 100
                                    : 100
                            }
                        />
                        <Typography
                            variant="body2"
                            sx={styles.completedText}
                            style={{
                                color: isUnpackingComplete() ? "#009245" : "#929292",
                            }}
                        >
                            {unpackedVignetteCount} / {minimumUnpackingCount} completed
                        </Typography>
                        <Box style={{ marginTop: "15px" }}>
                            {
                                isUnpackingComplete() ? (
                                    <Button sx={styles.btnActive} onClick={() => finishUnpacking()}>
                                        Submit
                                    </Button>
                                ) : (
                                    <Button disabled sx={styles.btnInactive}>
                                        Submit
                                    </Button>
                                )
                            }
                            
                        </Box>
                    </Box>
                </Grid>
                {selectedDomain === "All" && (
                    <AllCategoryVignettes
                        flaggedVignettes={flaggedVignettes}
                        chooseSelectedDomain={chooseSelectedDomain}
                        openUnpackingDialog={openUnpackingDialog}
                    />
                )}
                {selectedDomain != "All" && (
                    <SelectedCategoryVignettes
                        flaggedVignettes={flaggedVignettes}
                        selectedDomain={selectedDomain}
                        openUnpackingDialog={openUnpackingDialog}
                    />
                )}
            </Grid>
        </VSCard>
    );
}
