import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import CircularLoadingProgress from "components/CircularProgress";
import VSBox from "components/VSBox";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
const { REACT_APP_VSORTS_ORG_ID, REACT_APP_STEM4REAL_ORG_ID, REACT_APP_VORHEES_ORG_ID, REACT_APP_CALSTATE_ORG_ID } =
    process.env;
const LoginButton = ({ variant, color, hide }: any) => {
    const { loginWithRedirect, error } = useAuth0();
    const [searchParams] = useSearchParams();
    const invitation = searchParams.get("invitation");
    const invitation_organization = searchParams.get("organization");

    const [organization_id, set_organization_id] = useState<any>("");

    return (
        <>
            <VSBox className={hide ? "visibility-hidden" : ""}>
                <Button
                    onClick={() => {
                        console.log(invitation, invitation_organization);
                        if (invitation && invitation_organization) {
                            loginWithRedirect({
                                redirectUri: `${window.location.origin}/dashboard`,
                                invitation: invitation,
                                organization: invitation_organization,
                            });
                        } else {
                            if (window.location.origin.includes("stem4real")) {
                                loginWithRedirect({
                                    redirectUri: `${window.location.origin}/dashboard`,
                                    organization: REACT_APP_STEM4REAL_ORG_ID,
                                });
                            } else if (window.location.origin.includes("calstate")) {
                                loginWithRedirect({
                                    redirectUri: `${window.location.origin}/dashboard`,
                                    organization: REACT_APP_CALSTATE_ORG_ID,
                                });
                            } else if (window.location.origin.includes("vorhees")) {
                                loginWithRedirect({
                                    redirectUri: `${window.location.origin}/dashboard`,
                                    organization: REACT_APP_VORHEES_ORG_ID,
                                });
                            } else if (window.location.origin.includes("localhost")) {
                                loginWithRedirect({
                                    redirectUri: `${window.location.origin}/dashboard`,
                                    // organization: REACT_APP_VSORTS_ORG_ID,
                                });
                            } else {
                                loginWithRedirect({
                                    redirectUri: `${window.location.origin}/dashboard`,
                                });
                            }
                        }
                    }}
                    variant={variant ? variant : "contained"}
                    color={color ? color : "primary"}
                    size="small"
                    style={{ marginRight: "20px" }}
                    id="auth0-login-button"
                >
                    Login
                </Button>
            </VSBox>
            {hide && <CircularLoadingProgress />}
        </>
    );
};

export default LoginButton;
