import { useQuery } from "react-query";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

const fetchSubscriptionDetails = () => {
    return request({ url: NetworkConstants.getSubscriptionDetails });
};

// useGetScoredVignettesData -> To fetch All Vignettes that have been scored
export const useGetSubscriptionDetails = ({ onSuccess, onError, isEnabled = true }: any) => {
    console.log(true)
  return useQuery(
      [QueryKeyConstants.fetchSubscriptionDetails],
      () => fetchSubscriptionDetails(),
      {
          enabled: isEnabled,
          staleTime: Infinity,
          onSuccess,
          onError,
          select: (data) => {
            console.log(data)
              return data.data;
          },
      }
  );
};