import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// Axios Interceptor
import { request } from "utils/axios-utils";

// Constants
import { APIMethodConstants } from "constants/api.method.constants";
import { FormTypes } from "constants/enum.constants";
import { NetworkConstants } from "constants/network.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

import { Cluster, ClusterStatus } from "types/dashboard";
import { ClusterUser } from "types/user";
import { SwalAlert } from "utils/swal-utils";

export const fetchAllVSortsClusters = () => {
    return request({ url: NetworkConstants.getAllVSortsClusters });
};

export const fetchAllAssignedVSortsClusters = () => {
    return request({ url: NetworkConstants.getAllAssignedVSortsClusters });
};

export const fetchUserAssignedVSortsClusters = () => {
    return request({ url: NetworkConstants.getUserAssignedVSortsClusters });
};
export const fetchAdminAssignedVSortsClusters = () => {
    return request({ url: NetworkConstants.getAdminAssignedVSortsClusters });
};

const createNewVSortsContainer = ({ data }: any) => {
    return request({ url: NetworkConstants.createVSortsClusters, method: APIMethodConstants.post, data: data });
};

export const deployCluster = ({ apiMethodType, clusterId }: any) => {
    return request({ url: NetworkConstants.deployVSortCluster + clusterId, method: apiMethodType });
};

export const fetchOrganizations = ({ containerID, containerType }: any) => {
    return request({
        url: `${NetworkConstants.getOrganizations}?containerType=${containerType}&containerID=${containerID}`,
    });
};

const deleteOrganizations = ({ data }: any) => {
    return request({
        url: `${NetworkConstants.deleteOrganizations}`,
        method: APIMethodConstants.delete,
        data: data,
    });
};

export const deleteVSortsCluster = (id: any, data: any) => {
    return request({
        url: `${NetworkConstants.deleteVSortsCluster}/${id}`,
        method: APIMethodConstants.post,
        data: data,
    });
};

export const deleteMultipleVSortsClusters = (data: any) => {
    return request({
        url: `${NetworkConstants.deleteMultipleVSortsClusters}`,
        method: APIMethodConstants.post,
        data,
    });
};

const assignOrganization = ({ data }: any) => {
    return request({
        url: NetworkConstants.assignOrganization,
        method: APIMethodConstants.put,
        data: data,
    });
};

// useVSortsClusterNamesData -> To fetch All VSorts Clusters
export const useVSortsClusterNamesData = ({ onSuccess, onError }: any) => {
    return useQuery(QueryKeyConstants.fetchVSortsClusterQueryKey, fetchAllAssignedVSortsClusters, {
        staleTime: 300000,
        onSuccess,
        onError,
        select: (data) => {
            const clusters: Cluster[] = data.data.data.map((cluster: any) => ({
                id: cluster.id,
                title: cluster.cluster_name,
                url: cluster.cluster_url,
                status: ClusterStatus.NOT_STARTED,
                createdAt: cluster.created_at,
                published: cluster.published == 1 ? true : false,
                users: cluster.users
                    ? cluster.users.map(
                          (user: any) =>
                              ({
                                  id: user.user_id,
                                  first_name: user.first_name || "",
                                  last_name: user.last_name || "",
                                  name: user.name || "",
                                  email: user.email || "",
                              } as ClusterUser)
                      )
                    : [],
            }));

            //to do: remove this kept only to display different cluster buttons
            // clusters[1].status = ClusterStatus.RESULTS_PENDING;
            // clusters[2].status = ClusterStatus.IN_PROGRESS;
            // clusters[3].status = ClusterStatus.COMPLETED;
            return clusters;
        },
    });
};

export const useUserAssignedClusters = ({ onSuccess, onError, isEnabled = true }: any) => {
    return useQuery(QueryKeyConstants.fetchVSortsClusterQueryKey, fetchUserAssignedVSortsClusters, {
        // staleTime: 100000,
        enabled: isEnabled,
        onError,
        select: (data) => {
            const clusters: Cluster[] = data.data.data.map((cluster: any) => ({
                id: cluster.id,
                title: cluster.cluster_name,
                url: cluster.cluster_url,
                status: cluster.status,
                createdAt: cluster.created_at,
                published: cluster.published == 1 ? true : false,
                completedStatus: cluster.completedStatus,
                last_modified: cluster.last_modified,
                users: cluster.users
                    ? cluster.users.map(
                          (user: any) =>
                              ({
                                  id: user.user_id,
                                  first_name: user.first_name || "",
                                  last_name: user.last_name || "",
                                  name: user.name || "",
                                  email: user.email || "",
                              } as ClusterUser)
                      )
                    : [],
            }));

            //to do: remove this kept only to display different cluster buttons
            // clusters[1].status = ClusterStatus.RESULTS_PENDING;
            // clusters[2].status = ClusterStatus.IN_PROGRESS;
            // clusters[3].status = ClusterStatus.COMPLETED;
            return clusters;
        },
    });
};

export const useAdminAssignedClusters = ({ onSuccess, onError }: any) => {
    return useQuery(QueryKeyConstants.fetchVSortsClusterQueryKey, fetchAdminAssignedVSortsClusters, {
        staleTime: 300000,
        onSuccess,
        onError,
        select: (data) => {
            const clusters: Cluster[] = data.data.data.map((cluster: any) => ({
                id: cluster.id,
                title: cluster.cluster_name,
                url: cluster.cluster_url,
                status: ClusterStatus.NOT_STARTED,
                createdAt: cluster.created_at,
                published: cluster.published == 1 ? true : false,
                org_id: cluster.org_id,
                org_names: cluster.org_names,
                users: cluster.users
                    ? cluster.users.map(
                          (user: any) =>
                              ({
                                  id: user.user_id,
                                  first_name: user.first_name || "",
                                  last_name: user.last_name || "",
                                  name: user.name || "",
                                  email: user.email || "",
                              } as ClusterUser)
                      )
                    : [],
            }));

            //to do: remove this kept only to display different cluster buttons
            // clusters[1].status = ClusterStatus.RESULTS_PENDING;
            // clusters[2].status = ClusterStatus.IN_PROGRESS;
            // clusters[3].status = ClusterStatus.COMPLETED;
            return clusters;
        },
    });
};

// useCreateVSortsClusterData -> To create a New VSorts Cluster
export const useCreateVSortsClusterData = ({ data }: any) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(() => createNewVSortsContainer({ data: data }), {
        // We add the data from the response to the existing data
        onSuccess: (data) => {
            const responseData = data.data.data;
            queryClient.setQueryData(QueryKeyConstants.fetchVSortsClusterQueryKey, (oldQueryData: any) => {
                return {
                    ...oldQueryData,
                    data: {
                        data: [...oldQueryData.data.data, responseData],
                    },
                };
            });
            // Navigating to create screen on successful creation of a VSorts Cluster
            navigate("create/" + responseData.id + "?name=" + responseData.cluster_name);
        },
        onError: (error: any) => {
            SwalAlert({ title: error.response.data.status, description: error.response.data.message });
        },
    });
};

export const useCreateVSortsCluster = () => {
    const queryClient = useQueryClient();

    return useMutation((data: any) => createNewVSortsContainer({ data: data }), {
        // We add the data from the response to the existing data
        onSuccess: (data) => {
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey);
        },
        onError: (error: any) => {
            SwalAlert({ title: error.response.data.status, description: error.response.data.message });
        },
    });
};

// useDeployClusterMutation -> To deploy a VSorts cluster
export const useDeployClusterMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(({ clusterId, apiMethodType }: any) => deployCluster({ clusterId, apiMethodType }), {
        onSuccess: () => {
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsClusterQueryKey);
            queryClient.invalidateQueries(QueryKeyConstants.fetchVSortsContainerQueryKey);
            Object.keys(FormTypes).forEach((surveyType) =>
                queryClient.invalidateQueries([QueryKeyConstants.fetchSurveyQueryKey, surveyType])
            );
        },
    });
};

export const useFetchOrganizations = ({ containerID, containerType, onSuccess, onError, isEnabled }: any) => {
    return useQuery([QueryKeyConstants.fetchOrganizations], () => fetchOrganizations({ containerID, containerType }), {
        // staleTime: Infinity,
        enabled: isEnabled,
        onSuccess,
        onError,
        select: (data) => {
            return data.data.data;
        },
    });
};

export const useAssignedOrganization = ({ data, onSuccess, onError }: any) => {
    return useMutation(() => assignOrganization({ data: data }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const useDeleteOrganizations = ({ data, onSuccess, onError }: any) => {
    return useMutation(() => deleteOrganizations({ data: data }), {
        onSuccess: onSuccess,
        onError: onError,
    });
};
