import { Box, Chip } from "@mui/material";
import { ClusterStep } from "types/clusters";
import styles from "./styles";

function ClusterSummary({ clusterStepItems, handleAddClusterActivity }: any) {
    const typeName = (type: string | undefined) => {
        switch (type) {
            case "presurvey":
                return "Form";
            case "postsurvey":
                return "Form";
            case "vsorts":
                return "VSet";
            case "unpacking":
                return "Unpacking";
            case "finalfeedback":
                return "Form";
            default:
                return "-";
        }
    };

    return (
        <Box sx={styles.container}>
            {clusterStepItems.map((stepItem: ClusterStep) => {
                return typeName(stepItem.formType) == "-" ? (
                    <Chip sx={styles.clusterButton(false)} label={" - "} variant="outlined" />
                ) : (
                    <Chip sx={styles.badge} label={typeName(stepItem.formType)} variant="outlined" />
                );
            })}

            <Chip
                sx={styles.clusterButton(true)}
                label={"+ Add cluster activity"}
                variant="outlined"
                onClick={() => handleAddClusterActivity()}
            />
        </Box>
    );
}

export default ClusterSummary;
