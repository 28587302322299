// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "pages/layouts/navigation/LoginButton";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const LoginPage = () => {
    const { isAuthenticated, error } = useAuth0();

    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            const loginBtn = document.getElementById("auth0-login-button");
            if (loginBtn) {
                loginBtn.click();
            }
        }
    }, [isAuthenticated]);

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    } else if (error) {
        console.log("Login page", error);

        // alert("Access Denied: You do not have permission to access this application.");
        // navigate(`/`);
    }

    return <LoginButton hide />;
};

export default LoginPage;
