import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./individualStyles";

export default function IndividualCoPilot({ coPilot, index }: any) {
    const navigate = useNavigate();

    return (
        <div style={{ width: "100%" }}>
            {index == 0 && (
                <Button variant="outlined" style={styles.newBtn} onClick={() => navigate("/admin")}>
                    New
                </Button>
            )}
            <Typography sx={styles.title}>{coPilot?.title}</Typography>
            <Typography sx={styles.subTitle}>{coPilot?.sub_title}</Typography>

            <Button variant="outlined" style={styles.startBtn} onClick={() => navigate(`/aicopilot/${coPilot?.id}`)}>
                Start
            </Button>
        </div>
    );
}
