import { Grid, Typography } from "@mui/material";

const Footer = () => {
    return (
        <Grid
            container
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                gap: "10px",
                fontFamily: "Avenir",
                color: "#929292",
            }}
        >
            <Typography>VSorts™ AI ©2024 Culturally Responsive Solutions, LLC</Typography>
            <Typography>Terms | Privacy</Typography>
        </Grid>
    );
};

export default Footer;
